////// AnyDesk Buttons

//// Standard Buttons

.btn {
  font-weight: 700;
  font-size: 1rem;
  border-radius: 6px;
  padding: 10px 20px;
  text-decoration: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: 0;
  position: relative;
  line-height: 1.5;

  &:hover {
    opacity: 1;
  }

  &:focus {
    box-shadow: none !important;
  }

  &:active {
    box-shadow: 0 0 10px 3px $primary;

    &::after {
      box-shadow: 0 0 0 0 $primary;
      position: absolute;
      border-radius: 6px;
      left: 0;
      top:0;
      opacity: 1;
      transition: 0s;
    }
  }

  &:after {
    content: "";
    display: block;
    position: absolute;
    border-radius: 6px;
    left: 0;
    top:0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: all 0.5s;
    box-shadow: 0 0 5px 15px $primary;
  }

  &.dropdown-toggle {
    border: 1px solid white !important;
  }
}

.btn-sm {
  padding: 10px 20px;
  line-height: 1.5;
}

.btn-xs {
  padding: 0 15px;

  @media (min-width: 768px) {
    padding: 0 20px;
  }

  @media (min-width: 1200px) {
    font-size: 1rem;
  }
}

.btn-small {
  padding: 5px 10px;
  font-weight: 400;
}

.btn-fix-border {
  border-bottom: none;
  
  &:hover {
    border-bottom: none;
  }
}

.btn-primary {
  /*background-image: linear-gradient(to right, $primary 0%, $primary 51%, $orange 100%);
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  background-size: 200% auto;*/
  position: relative;
  outline: none;
  overflow: hidden;

  &::before {
    --size: 0;
    content: '';
    position: absolute;
    left: var(--x);
    top: var(--y);
    width: var(--size);
    height: var(--size);
    background: radial-gradient(circle closest-side, $orange, transparent);
    transform: translate(-50%, -50%);
    transition: width 0.2s ease, height 0.2s ease;
  }

  &:hover {
    &:before {
      --size: 100px;
    }
    /*background-image: linear-gradient(to right, $primary 0%, $primary 51%, $orange 100%);
    background-position: right center;*/
  }

  &:active {
    /*background-image: linear-gradient(to right, $primary 0%, $primary 51%, $orange 100%) !important;*/
  }

  p, span {
    position: relative;
    pointer-events: none;
    margin-bottom: 0;
  }
}

.btn-primary-gradient {
  background: linear-gradient(90deg, $primary 0%, $orange 100%);
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  background-size: 200% auto;

  &:hover {
    background-image: linear-gradient(to right, $primary 0%, $primary 51%, $orange 100%);
    background-position: right center;
  }

  &:active {
    background-image: linear-gradient(to right, $primary 0%, $primary 51%, $orange 100%) !important;
  }
}


// Outline Buttons

.btn-primary-outline {
  background-color: transparent;
  color: $primary !important;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 6px;
    border: 2px solid transparent;
    background: linear-gradient(90deg, $primary 0%, $primary 100%) border-box;
    -webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
    -webkit-mask-composite: destination-out;
    mask-composite: exclude;
  }

  &:hover {
    background-image: $primary-orange-gradient-bg !important;
    color: $white !important;

    &::before {
      background-image: $primary-orange-gradient-bg !important;
      border: 0;
    }

    p {
      color: $white !important;
    }
  }

  &:active {
    background-color: rgba($primary,.3);
  }

  p {
    color: $primary !important;
  }
}

.btn-white {
  background-color: $white;
  color: $primary !important;

  &:hover {
    background-color: black;

    p {
      color: $white;
      -webkit-text-fill-color: unset;
      background: none;
    }
  }

  &:active {
    background-color: $red-mega-light !important;
    color: $white !important;
  }

  p {
    @extend .primary-orange-gradient;
  }
}

.btn-white-outline {
  background-color: transparent;
  border: 2px solid white;
  color: white !important;
  padding: 5px 15px;

  @media (min-width: 768px) {
    padding: 8px 20px;
  }

  &:hover {
    background-color: rgba($white,.2);
    color: white;
    border: 2px solid white;
  }

  &:active {
    background-color: rgba($white,.3);
    color: white !important;
  }

  p {
    color: white !important;
  }
}

.btn-black {
  background-color: $black;
  border: 2px solid $black;
  color: $white !important;

  &:hover {
    background-color: $black;
    color: $primary !important;
    border: 2px solid $black;
  }

  &:active {
    background-color: $black;
    color: $white !important;
  }

  p {
    color: $white !important;
  }
}

.btn-red {
  background-color: #cc0000;
  color: $white !important;

  &:hover {
    background-color: #cc0000;
    color: $white;
  }

  &:active {
    background-color: #cc0000;
    color: $white !important;
  }

  p {
    color: $white !important;
  }
}

.btn-gray {
  background-color: $gray-medium;
  color: $white !important;

  &:hover {
    background-color: $gray-medium;
    color: $white;
  }

  &:active {
    background-color: $gray-medium;
    color: $white !important;
  }

  p {
    color: $white !important;
  }
}

.btn-dark-gray {
  background-color: $gray;
  color: $white !important;

  &:hover {
    background-color: $gray;
    color: $white;
  }

  &:active {
    background-color: $gray;
    color: $white !important;
  }

  p {
    color: $white !important;
  }
}

.btn-purple {
  background-image: linear-gradient(to right, #BE70DF 0%, #1D49A7  51%, #BE70DF  100%);
  transition: 0.5s;
  background-size: 200% auto;
  color: $white;

  &:hover {
    background-position: right center;
  }

  &:active {
    background-image: linear-gradient(to right, #BE70DF 0%, #1D49A7  51%, #BE70DF  100%) !important;
  }
}

.btn-purple-outline {
  background-color: transparent;
  border: 2px solid $purple-light;
  color: $purple-light !important;

  &:hover {
    background-color: rgba($purple-medium,.1);
    color: $purple-medium;
    border: 2px solid $purple-light;
  }

  &:active {
    background-color: rgba($purple-medium,.2) !important;
    color: $purple-medium !important;
  }

  p {
    color: $purple-medium !important;
  }
}

.btn-white-onlyDarkmode {
  background-image: linear-gradient(to right, $primary 0%, $primary  51%, $orange  100%);
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  background-size: 200% auto;
  color: white;

  &:hover {
    background-image: linear-gradient(to right, $primary 0%, $primary  51%, $orange  100%);
    background-position: right center;
    color: white;
  }

  &:active {
    background-image: linear-gradient(to right, $primary 0%, $primary  51%, $orange  100%) !important;
  }
}