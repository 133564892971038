////// AnyDesk Animations

:root {
  --animate-delay: 0.5s !important;
}

.any-zoom {
  @extend .any-transition-fade;

  &:hover {
    transform: scale(1.01);
  }
}

.any-zoom-1-2 {
  @extend .any-transition-fade;

  &:hover {
    transform: scale(1.2);
  }
}

.any-glow-red {
  position: absolute;
  top: 85px;
  right: 80px;
  background-color: transparent;
  animation: glow-red 4s infinite alternate;
  z-index: 1;
  opacity: .5;
  display: none;

  @media (min-width: 768px) {
    display: block;
    right: auto;
    top: 65px;
    left: 100px;
  }

  @media (min-width: 992px) {
    top: 110px;
    left: 214px;
  }

  @media (min-width: 1200px) {
    top: 150px;
    left: 300px;
  }
}

@keyframes glow-red {
  from {
    box-shadow: 0 0 60px 60px rgba($primary, 0.5);
  }
  to {
    box-shadow: 0 0 100px 100px ($primary, 0.5);
  }
}

@media (min-width: 768px) {
  @keyframes glow-red {
    from {
      box-shadow: 0 0 40px 40px $primary;
    }
    to {
      box-shadow: 0 0 80px 80px $primary;
    }
  }
}

@media (min-width: 992px) {
  @keyframes glow-red {
    from {
      box-shadow: 0 0 60px 60px $primary;
    }
    to {
      box-shadow: 0 0 100px 100px $primary;
    }
  }
}

@media (min-width: 1200px) {
  @keyframes glow-red {
    from {
      box-shadow: 0 0 80px 80px $primary;
    }
    to {
      box-shadow: 0 0 130px 130px $primary;
    }
  }
}