////// AnyDesk Utilities

//// Widths & Heights

.w-sm-30px {
  @media (max-width: 767px) {
    width: 30px;
  }
}

.w-50 {
  width: 50%;
}

.w-md-50 {
  width: 100%;
  @media (min-width: 992px) {
    width: 50%;
  }
}

.w-60 {
  width: 60%;
}

.w-70 {
  width: 70%;
}

.w-80 {
  width: 80%;
}

.w-90 {
  width: 90%;
}

.w-100 {
  width: 100%;
}

.w-auto {
  width: auto;
}

.w-180px {
  width: 180px
}

.w-120px {
  width: 120px
}

.h-50px {
  height: 50px;
}

.h-max-content {
  height: max-content;
}

.min-height-40 {
  min-height: 40px;
}

.min-height-60 {
  min-height: 60px;
}

.min-height-140 {
  min-height: 140px;
}

.min-height-mt-minus-form {
  min-height: auto;

  @media (min-width: 992px) {
    min-height: 490px;
  }
}

.min-height-mt-minus-textimgvideo {
  min-height: auto;

  @media (min-width: 992px) {
    min-height: 400px;
  }
}

.max-height-450 {
  max-height: 450px;
}

.max-height-250 {
  max-height: 250px;
}

.min-width-340 {
  @media (min-width: 576px) {
    min-width: 340px !important;
  }
}

.min-width-200 {
  min-width: 200px;
}

.min-width-100percent {
  min-width: 100% !important;
}

.max-width-160percent {
  max-width: 100%;

  @media (min-width: 1200px) {
    max-width: 160%;
  }
}

.max-width-922px {
  max-width: 922px;
}

.max-width-180px {
  max-width: 180px;
}

.max-width-350px {
  max-width: 100%;

  @media (min-width: 576px) {
    max-width: 350px;
  }
}

.height-73-sm {
  height: auto;

  @media (min-width: 768px) {
    height: 73px;
  }
}

.h-lg-100 {
  height: auto !important;

  @media (min-width: 992px) {
    height: 100% !important;
  }
}

.left-0 {
  left: 0;
}

.left-minus90px {
  left: -90px !important;
}

.right-0 {
  right: 0;
}

.right-25 {
  right: 25px;
}

.top-0 {
  top: 0;
}

.bottom-0 {
  bottom: 0;
}

.bottom-5 {
  bottom: 5px;
}

.top-15 {
  top: 15px;
}

.top-5 {
  top: 5px;
}

.right-15 {
  right: 15px;
}

//// Font-Weights

.bold {
  font-weight: 700 !important;
}

.semi-bold {
  font-weight: 500 !important;
}

.normal {
  font-weight: 400 !important;
}

.regular {
  font-weight: 300 !important;
}

.semi-light {
  font-weight: 200 !important;
}

.light {
  font-weight: 100 !important;
}


//// Colors

.white {
  color: $white !important;
}

.info {
  color: $info !important;
}

.gray {
  color: $gray !important;
}

.gray-medium {
  color: $gray-medium !important;
}

.gray-light-50 {
  color: $gray-light-50 !important;
}

.red {
  color: $primary !important;
}

.red-light {
  color: $primary-light !important;
}

.green {
  color: $green !important;
}

.font-color {
  color: $body-color;
}

.font-color-gray {
  color: $gray-font;
}

.purple {
  color: $purple !important;
}

.purple-light {
  color: $purple-light !important;
}

.primary-orange-gradient {
  background: $primary-orange-gradient-text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.primary-orange-black-gradient {
  background: $primary-orange-black-gradient-text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}


//// Fonts

.font-size-mega {
  font-size: 5rem;
  line-height: 7rem;

  @media (min-width: 576px) {
    font-size: 9rem;
    line-height: 11rem;
  }

  @media (min-width: 768px) {
    font-size: 7rem;
    line-height: 9rem;
  }

  @media (min-width: 992px) {
    font-size: 9rem;
    line-height: 11rem;
  }

  @media (min-width: 1200px) {
    font-size: 11rem;
    line-height: 13rem;
  }
}

.font-size-xxl {
  font-size: 4.5rem;
  line-height: 5rem;

  @media (min-width: 576px) {
    font-size: 5rem;
    line-height: 5.6rem;
  }
}

.font-size-xl {
  font-size: 3rem;
  line-height: 4rem;

  @media (min-width: 576px) {
    font-size: 3.5rem;
    line-height: 5rem;
  }
}

.font-size-xllg {
  font-size: 2.2rem;
  line-height: 3rem;

  @media (min-width: 576px) {
    font-size: 2.8rem;
    line-height: 4rem;
  }
}

.font-size-lgxl {
  font-size: 1.8rem;
  line-height: 2.2rem;

  @media (min-width: 768px) {
    font-size: 2.4rem;
    line-height: 3rem;
  }
}

.font-size-lg {
  font-size: 1.5rem;
  line-height: 2rem;

  @media (min-width: 576px) {
    font-size: 2rem;
    line-height: 2.6rem;
  }
}

.font-size-md {
  font-size: 1.25rem;
  line-height: 1.75rem;

  @media (min-width: 768px) {
    font-size: 1.5rem;
    line-height: 2rem;
  }
}

.font-size-mdsm {
  font-size: 1.125rem;
  line-height: 1.75rem;

  @media (min-width: 576px) {
    font-size: 1.25rem;
    line-height: 2rem;
  }
}

.font-size-mdsm2 {
  font-size: 1.125rem;
  line-height: 1.75rem;

  @media (min-width: 576px) {
    font-size: 1.18rem;
    line-height: 2rem;
  }
}

.font-size-mdsm3 {
  font-size: 0.8rem;
  line-height: 1.1rem;

  @media (min-width: 576px) {
    font-size: 1.25rem;
    line-height: 2rem;
  }
}

.font-size-smxs {
  font-size: .75rem;
  line-height: 1.2rem;

  @media (min-width: 1200px) {
    font-size: 0.9rem;
    line-height: 1.8;
  }
}

.font-size-sm, .font-size-normal {
  font-size: 1rem;
}

.font-size-xs {
  font-size: .75rem;
  line-height: 1.2rem;
}

.font-size-xxs {
  font-size: 0.5rem;
  line-height: 1rem;
}


.font-size-xl-md {
  font-size: 2.5rem;

  @media (min-width: 992px) {
    font-size: 3.5rem;
  }

  @media (min-width: 1800px) {
    font-size: 4.5rem;
  }
}



//// Positioning

.pos-rel {
  position: relative;
}

.pos-abs {
  position: absolute;
}

.pos-abs-md {
  position: relative;

  @media (min-width: 992px) {
    position: absolute;
  }
}

//// Borders

.border-solid-gray {
  border: 1px solid $gray-medium;
}

.border-solid-primary {
  border: 1px solid $primary !important;
}

.border-solid-transparent {
  border: 1px solid transparent;
}

.border-solid-primary-3px {
  border: 3px solid $primary;
}

.border-solid-gradient-2px-radius {
  border: 2px solid #0000 !important;
  background: conic-gradient(#fff 0 0) padding-box, linear-gradient(45deg,$primary,$orange) border-box !important;
  border-radius: 6px !important;
}

.border-solid-gradient-2px {
  border: 2px solid transparent !important;
  border-image: $primary-orange-gradient-border !important;
  border-image-slice: 1 !important;
}

.border-solid-gradient-3px {
  border: 3px solid transparent !important;
  border-image: $primary-orange-gradient-border !important;
  border-image-slice: 1 !important;
}

.border-solid-gradient-5px-180deg {
  border: 5px solid transparent !important;
  border-image: $primary-orange-gradient-border-180deg !important;
  border-image-slice: 1 !important;
}

.border-solid-gradient-5px-animated {
  border: 5px solid #0000;
  background: linear-gradient(#ffffff, #ffffff) padding-box, linear-gradient(var(--angle), $primary, $orange) border-box;
  animation: 4s rotate linear infinite;
}

/* ANIMATED BORDER FRAMES */
@keyframes rotate {
  to {
    --angle: 360deg;
  }
}

@property --angle {
  syntax: "<angle>";
  initial-value: 0deg;
  inherits: false;
}
/* ANIMATED BORDER FRAMES */

.border-1px-solid-primary {
  border: 1px solid $primary !important;
}

.border-right-solid-gray {
  border-right: 1px solid $gray-medium;
}

.border-right-solid-gray-sm-up {
  border-right: none;

  @media (min-width: 576px) {
    border-right: 1px solid $gray-medium;
  }
}

.border-right-solid-primary-lg-up {
  border-right: none;

  @media (min-width: 991px) {
    border-right: 1px solid $primary;
  }
}

.border-right-solid-gray-md-up {
  border-right: none;

  @media (min-width: 768px) {
    border-right: 1px solid $gray-medium;
  }
}

.border-right-solid-gray-md-down {
  border-right: 1px solid $gray-medium;

  @media (min-width: 768px) {
    border-right: none;
  }
}

.border-bottom-solid-gray {
  border-bottom: 1px solid $gray-medium !important;
}

.border-bottom-solid-gray-5px {
  border-bottom: 5px solid $gray !important;
}

.border-bottom-solid-primary {
  border-bottom: 1px solid $primary;
}

.border-bottom-solid-gradient {
  border-bottom: 1px solid transparent;
  border-image: $primary-orange-gradient-border;
  border-image-slice: 1;
}

.border-bottom-solid-white-3px {
  border-bottom: 3px solid white !important;
}

.border-top-solid-primary-5px {
  border-top: 5px solid $primary;
}

.border-top-solid-gradient-5px {
  border-top: 5px solid transparent !important;
  border-image: $primary-orange-gradient-border !important;
  border-image-slice: 1 !important;
}

.border-top-solid-gray-medium-5px {
  border-top: 5px solid $gray-medium;
}

.border-top-solid-gray-5px {
  border-top: 5px solid $gray;
}

.border-top-solid-gray-darker-5px {
  border-top: 5px solid $gray-darker;
}

.border-top-solid-gray-light-5px {
  border-top: 5px solid $gray-light;
}

.border-top-solid-gray-light-2px {
  border-top: 2px solid $gray-light;
}

.border-top-solid-gray-medium-2px {
  border-top: 2px solid $gray-medium;
}

.border-top-solid-gray-2px {
  border-top: 2px solid $gray;
}

.border-top-solid-gray-darker-2px {
  border-top: 2px solid $gray-darker;
}

.border-top-solid-primary-2px {
  border-top: 2px solid $primary;
}

.border-top-transparent-5px {
  border-top: 5px solid transparent;
}

.border-top-transparent-2px {
  border-top: 2px solid transparent;
}

.border-radius-pill {
  border-top-left-radius: 9999px !important;
  border-top-right-radius: 9999px !important;
  border-bottom-left-radius: 9999px !important;
  border-bottom-right-radius: 9999px !important;
}

.border-radius-6px {
  border-radius: 6px;
}

.border-none {
  border: none !important;
}

.border-bottom-none {
  border-bottom: none !important;
}


//// Alignment

.v-align-top {
  vertical-align: top;
}


//// Backgrounds

.bg-color-gray {
  background-color: $gray-light;
}

.bg-color-primary {
  background-color: $primary;
}

.bg-color-primary-medium {
  background-color: $primary-medium;
}

.bg-color-primary-light {
  background-color: $primary-light;
}

.bg-color-gray-dark {
  background-color: $gray-dark;
}

.bg-color-gray-dark-transparent {
  background-color: rgba($gray-dark, .9);
}

.bg-color-gray-darker {
  background-color: $gray-darker;
}

.bg-color-transparent {
  background-color: transparent;
}

.bg-color-white {
  background-color: white;
}

.bg-color-gray-transparent {
  background-color: rgba(248, 248, 248, .70);
}

.bg-color-gray-gradient {
  background: linear-gradient(#FFFFFF, #EBEBEB);
}

.bg-color-primary-orange-gradient {
  background: $primary-orange-gradient-bg;
}

.bg-color-gray-sm {
  @media (max-width: 575px) {
    background-color: $gray-light;
  }
}

.bg-img-none-sm {
  @media (max-width: 575px) {
    background-image: none !important;
  }
}

.bg-white-transparent {
  background-color: rgba(255,255,255,0.4);

  [type="checkbox"]+label:before {
    border: none !important;
  }
}

.bg-pos-y-0 {
  background-position-y: 0;
}

.bg-pos-top {
  background-position: top;
}

.bg-pos-bottom {
  background-position: bottom;
}

.bg-pos-right {
  background-position: right;
}


//// Colours

.blue-dark{
  color: $blue-dark;
}

//// Cursor

.cursor-pointer {
  cursor: pointer;
}


//// Margins & Paddings

.mr-2px {
  margin-right: 2px;
}

.my-2-3 {
  margin: 1.5rem 0;
}

.my-0-1 {
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
}

.mt-1px {
  margin-top: 1px;
}

.mt-3px {
  margin-top: 3px;
}

.mb-1-2 {
  margin-bottom: 0.75rem;
}

.ml-0-1 {
  margin-left: 0.25rem;
}

.mb-0-1 {
  margin-bottom: 0.25rem;
}

.ml-2px {
  margin-left: 2px;
}

.ml-3px {
  margin-left: 3px;
}

.ml-7px {
  margin-left: 7px;
}

.mx-12px {
  margin-left: 0;
  margin-right: 0;

  @media (min-width: 1400px) {
    margin-left: 12px;
    margin-right: 12px;
  }
}

.ml-minus-1 {
  margin-left: -1px;
}

.ml-minus-3 {
  margin-left: -3px;
}

.ml-minus-110 {
  margin-left: -110px;
}

.ml-minus-150 {
  margin-left: 0;

  @media (min-width: 1200px) {
    margin-left: -150px;
  }
}

.mb-minus-50 {
  margin-bottom: -50px;
}

.mb-4px {
  margin-bottom: 4px;
}

.mt-minus-form, .mt-minus-video {
  margin-top: -30px;

  @media (min-width: 576px) {
    margin-top: -50px;
  }

  @media (min-width: 768px) {
    margin-top: -70px;
  }

  @media (min-width: 992px) {
    margin-top: -360px;
  }
}

.mt-minus-textimgvideo {
  margin-top: 0;

  @media (min-width: 992px) {
    margin-top: -100px;
  }
}

.mt-minus-50 {
  margin-top: -50px;
}

.mt-minus-80 {
  margin-top: 0;

  @media (min-width: 768px) {
    margin-top: -20px;
  }

  @media (min-width: 992px) {
    margin-top: -50px;
  }

  @media (min-width: 1200px) {
    margin-top: -80px;
  }
}

.mt-minus-100 {
  margin-top: -50px;

  @media (min-width: 768px) {
    margin-top: -70px;
  }

  @media (min-width: 992px) {
    margin-top: -85px;
  }

  @media (min-width: 1200px) {
    margin-top: -100px;
  }
}

.mt-minus-200 {
  margin-top: -200px;
}

.mt-minus-30 {
  margin-top: -30px;
}

.mr-minus-20 {
  margin-right: -20px;
}

.mt-0-1 {
  margin-top: 0.25rem;
}

.pt-lg-8 {
  @media (min-width: 992px) {
    padding-top: 8rem;
  }
}

.pb-7 {
  padding-bottom: 4rem !important;

  @media (min-width: 992px) {
    padding-bottom: 7rem !important;
  }
}

//// Text Decoration

.text-deco-underline {
  text-decoration: $link-hover-decoration;
}

.text-deco-none {
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }
}


//// Overflow

.overflow-hidden {
  overflow: hidden;
}

.overflow-visible {
  overflow: visible;
}


//// Z-Index
.z-index-0 {
  z-index: 0;
}

.z-index-0 {
  z-index: 0;
}

.z-index-1 {
  z-index: 1;
}

.z-index-2 {
  z-index: 2;
}

.z-index-3 {
  z-index: 3;
}

.z-index-4 {
  z-index: 4;
}

.z-index-98 {
  z-index: 98;
}

.z-index-9999 {
  z-index: 9999;
}


//// Opacity

.opacity-2 {
  opacity: .2;
}

.opacity-5 {
  opacity: .5;
}

.opacity-10 {
  opacity: 1 !important;
}

.opacity-1 {
  opacity: .1;
}

.opacity-05 {
  opacity: .05;
}


// Border radius

.border-radius-50 {
  border-radius: 50%;
}

.border-radius-25px {
  border-radius: 25px;
}

.border-radius-top-6px {
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}

.border-radius-bottom-6px {
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}


// Word break 

.word-break {
  word-wrap: break-word;
}


//hyphens 

.hyphens {
  hyphens: auto;
}


// Display

.d-inline-block-ni {
  display: inline-block;
}

.d-none-normal {
  display: none;
}


// Transitions

.no-trans {
  -webkit-transition: none !important;
  -moz-transition: none !important;
  -o-transition: none !important;
  transition: none !important;
}


// Box-Shadows

.any-boxshadow {
  box-shadow: 5px 3px 15px #0000002E;
}

.any-boxshadow-bottom {
  box-shadow: 0px 0px 20px #00000029;
}

.any-boxshadow-dark {
  box-shadow: 5px 3px 15px #0000006E;
}

.any-boxshadow-left-right {
  box-shadow: 6px 0 4px -4px #DBDBDB, -6px 0 4px -4px #DBDBDB;
}

.any-boxshadow-light-red {
  box-shadow: 0 0 5px $primary;
}

// Rotation

.rotate-001turn {
  transform: rotate(-0.01turn);
}

// Line Height

.line-height-15 {
  line-height: 1.5;
}

.line-height-normal {
  line-height: normal;
}

// Mirroring

.mirror-vertically {
  @extend .any-transition-fade;
  -moz-transform: scaleY(-1);
  -o-transform: scaleY(-1);
  -webkit-transform: scaleY(-1);
  transform: scaleY(-1);
  -ms-filter: "FlipH";
  filter: FlipH;
}

// Mirror and rotate

.mirrorY-rotate90 {
  transform: rotate(90deg) scaleY(-1);
}

// Pointer Events

.pointer-events-none {
  pointer-events: none;
}
