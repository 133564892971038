//// GLOBAL - stuff we need globally in all of our our websites

// Error Page Image

.any-error-image {
  margin-top: -40px;

  @media (min-width: 576px) {
    margin-top: -55px;
  }

  @media (min-width: 992px) {
    margin-top: -88px;
  }
}


// Back to top button

#any-backToTop {
  position: fixed;
  bottom: 10px;
  right: 10px;
  width: 45px;
  height: 45px;
  cursor: pointer;
  display: none;
  z-index: 1001;
  background-color: $primary;
  text-align: center;
  border-radius: 6px;

  @media (min-width: 992px) {
    display: none !important;
  }

  img {
    width: 25px;
    padding-top: 8px;
  }
}


// Scroll Down Button

.any-scrolldown-btn {
  display: block;
  position: absolute;
  bottom: 0;
  right: 15px;
  width: 60px;
  height: 60px;
  margin-bottom: 0;
  opacity: 0.9;

  @media (min-width: 768px) {
    margin-left: -40px;
    width: 80px;
    height: 80px;
  }
}


// Pulse Effect

@keyframes any-pulseeffect {
  from {
    transform: scale(1);
  }
  50% {
    transform: scale(0.90);
  }
  to {
    transform: scale(1);
  }
}

.any-pulseeffect {
  animation-name: any-pulseeffect;
  animation-duration: 3s;
  animation-iteration-count: infinite;
}
.any-pulseeffect:hover,
.any-pulseeffect:focus {
  animation-name: unset;
}


// Cookie Bar

.any-cookieBar {
  position: fixed;
  display: table;
  width: 100%;
  bottom: 0;
  right: 0;
  left: 0;
  height: auto;
  text-align: center;
  background-color: $bg-color-white-transparent;
  border-top: 1px solid $gray-medium;
  font-size: 1rem;
  z-index: 1000;
  line-height: 1.5;

  .any-cookieBar-message {
    display: none;
    padding: 5px 20px;

    @media (min-width: 768px) {
      display: table-cell;
      vertical-align: middle;
      padding: 5px 20px 5px 50px;
    }

    @media (min-width: 992px) {
      padding: 5px 20px 5px 100px;
    }

    @media (min-width: 1200px) {
      padding: 5px 20px 5px 200px;
    }
  }

  .any-cookieBar-messageMobile {
    display: table-cell;
    vertical-align: middle;
    padding: 5px 20px;

    @media (min-width: 768px) {
      display: none;
    }
  }

  .btn {
    margin-right: 60px;
    margin-top: 9px;
    padding: 0.3rem 1rem;
    line-height: 1.3;
    height: auto;

    @media (min-width: 992px) {
      margin-right: 100px;
    }

    @media (min-width: 1200px) {
      margin-right: 200px;
    }
  }
}

.any-cookieBar-close {
  display: none;
  &:checked + .any-cookieBar {
    animation-duration: 0.5s;
    animation-fill-mode: both;
    animation-name: slideOutDown;
  }
}

#btnCookieBarPlaceholder {
  height: 53px;
  width: 100%;
  display: block;
}


// Banner

.any-banner-wrapper {
  margin-top: -20px;

  @media (min-width: 768px) {
    margin-top: -30px;
  }

  @media (min-width: 992px) {
    margin-top: -50px;
  }
}

.any-banner {
  background-color: $bg-color-white-transparent;
  color: $body-color !important;
  text-align: center;
  font-size: 1.3rem;
  display: block;

  &:hover {
    opacity: .8;
  }

  .any-icon-sm {
    height: 30px;
    margin-bottom: 3px;
  }
}


//// Arrows

.any-arrow {
  border-width: 9px 9px;
  border-style: solid;
  border-color: transparent;
}

.any-arrow-up {
  @extend .any-arrow;
  border-bottom-color: $body-color;
}

.any-arrow-down {
  @extend .any-arrow;
  border-top-color: $body-color;
}

.any-arrow-down-red {
  @extend .any-arrow-down;
  border-top-color: $primary;
}

.any-arrow-dropdown-menu {
  top: -18px;
  left: auto;
  right: 26px;
  width: 0;
  height: 0;
  position: absolute;

  @media (min-width: 1200px) {
    right: 27px;
  }

  &::after {
    @extend .any-arrow-up;
    content: "";
    position: absolute;
    display: block;
    width: 0;
    height: 0;
    border-bottom-color: rgba(255,255,255,0.95);
  }
}

// Chat widget

.alotech-chat-widget {
  bottom: 57px !important;
  border-radius: 0 !important;

  .alotech-chat-head {
    background-color: $primary !important;
    border-radius: 0 !important;
    border: 1px solid white !important;
  }
}