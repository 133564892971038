////// AnyDesk Jumbotrons

//// Jumbotron White (Standard) on White Background

.jumbotron {
  padding: 1rem;
  border: 2px solid $gray-medium;
  border-radius: 0;
  text-align: center;

  @media (min-width: 576px) {
    padding: 2rem;
  }
}


//// Jumbotron White on Red Gradient

.jumbotron-transparent {
  background-color: $bg-color-white-transparent;
}


//// Jumbotron White with Quotes

.jumbotron-icon {
  position: relative;

  .jumbotron-icon-img, .jumbotron-icon-img-right {
    position: absolute;
    top: -35px;
    right: -10px;
    width: 55px;
    height: 55px;
    background-color: white;
    padding: 10px;

    @media (min-width: 576px) {
      right: -30px;
      top: -30px;
      width: 70px;
      height: 70px;
    }
  }

  .jumbotron-icon-img-left {
    @extend .jumbotron-icon-img;
    right: auto;
    left: -10px;

    @media (min-width: 576px) {
      right: auto;
      left: -30px;
    }
  }

  .jumbotron-icon-p {
    position: relative;
    margin-bottom: 0;

    @media (min-width: 768px) {
      position: absolute;
      bottom: 2rem;
      left: 2rem;
    }
  }
}


//// Jumbotron White (Standard) with Highlight Link on White Background

.jumbotron-highlightLink {
  @extend .any-transition-fade;
  
  &:hover {
    opacity: .6;
  }
}