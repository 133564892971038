////// AnyDesk List Group

.list-group {
    .list-group-item {
        display: grid;
        align-items: center !important;

        &:first-child {
            border-top-left-radius: 0;
            border-top-right-radius: 0;
        }
        
        &:last-child {
            border-bottom-right-radius: 0;
            border-bottom-left-radius: 0;
        }
    }
    .list-group-item-action {
        color: $black;
    }
    
    .active {
        color: $white !important;
        background: $primary !important;   
    }

    @media (min-width: 992px) {
        .list-group-item-action:active {
            color: $black;
            background-color: none;
        }
    }
}
