////// AnyDesk Input Groups

.input-group-append {
    margin-left: -48px;
    margin-top: 6px;
    z-index: 98;

    button {
        background: white;
        border: none;
        width: 48px;
        height: 43px;
    }
}

.input-group>.form-control:not(:first-child), .input-group>.custom-select:not(:first-child),
.input-group>.form-control:not(:last-child), .input-group>.custom-select:not(:last-child) {
    border-radius: 6px;
}