
//// Popover AnyDesk

.popover {
  background-color: $gray-medium;
  border: none;
  border-radius: 0;
  max-width: 80%;
  padding: 5px;

  @media (min-width: 992px) {
    max-width: 40%;
  }

  .popover-body {
    line-height: 1.3rem;
    font-size: .9rem;
  }
}

.any-popover {
  .form-check-label {
    text-decoration: underline;
    text-decoration-style: dotted;
  }
}

.any-popover-white {
  @extend .any-popover;
  color: white;
  
  span {
    &::after {
      background-image: url(../../_static/img/icons/streamline/messages-chat-smileys/messages-speech-bubbles/messages-bubble-question-white.svg);
    }
  }

  .form-check-label {
    text-decoration: underline;
    text-decoration-style: dotted;
  }
}

.bs-popover-auto[x-placement^=right] {
  margin-left: 1rem;

  .arrow {
    &::before {
      border-right-color: $gray-medium !important;
    }

    &::after {
      border-right-color: $gray-medium !important;
    }
  }
}

.bs-popover-right {
  margin-left: 1rem;

  .arrow {
    &::before {
      border-right-color: $gray-medium !important;
    }

    &::after {
      border-right-color: $gray-medium !important;
    }
  }
}

.bs-popover-auto[x-placement^=left] {
  margin-left: 1rem;

  .arrow {
    &::before {
      border-left-color: $gray-medium !important;
    }

    &::after {
      border-left-color: $gray-medium !important;
    }
  }
}

.bs-popover-left {
  margin-left: 1rem;

  .arrow {
    &::before {
      border-left-color: $gray-medium !important;
    }

    &::after {
      border-left-color: $gray-medium !important;
    }
  }
}

.bs-popover-auto[x-placement^=bottom] {
  margin-left: 1rem;

  .arrow {
    &::before {
      border-bottom-color: $gray-medium !important;
    }

    &::after {
      border-bottom-color: $gray-medium !important;
    }
  }
}

.bs-popover-bottom {
  margin-left: 1rem;

  .arrow {
    &::before {
      border-bottom-color: $gray-medium !important;
    }

    &::after {
      border-bottom-color: $gray-medium !important;
    }
  }
}

.bs-popover-auto[x-placement^=top] {
  margin-left: 1rem;

  .arrow {
    &::before {
      border-top-color: $gray-medium !important;
    }

    &::after {
      border-top-color: $gray-medium !important;
    }
  }
}

.bs-popover-top {
  margin-left: 1rem;

  .arrow {
    &::before {
      border-top-color: $gray-medium !important;
    }

    &::after {
      border-top-color: $gray-medium !important;
    }
  }
}
