////// AnyDesk Typography

//// Standard Typography

@font-face {
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url('../fonts/noto-sans/notosans-100.woff2'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/noto-sans/notosans-100.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/noto-sans/notosans-100.woff') format('woff'), /* Modern Browsers */
       url('../fonts/noto-sans/NotoSans-100.ttf') format('truetype'), /* Safari, Android, iOS */
}

@font-face {
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url('../fonts/noto-sans/notosans-200.woff2'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/noto-sans/notosans-200.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/noto-sans/notosans-200.woff') format('woff'), /* Modern Browsers */
       url('../fonts/noto-sans/NotoSans-200.ttf') format('truetype'), /* Safari, Android, iOS */
}

@font-face {
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url('../fonts/noto-sans/notosans-300.woff2'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/noto-sans/notosans-300.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/noto-sans/notosans-300.woff') format('woff'), /* Modern Browsers */
       url('../fonts/noto-sans/NotoSans-300.ttf') format('truetype'), /* Safari, Android, iOS */
}

@font-face {
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('../fonts/noto-sans/notosans-400.woff2'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/noto-sans/notosans-400.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/noto-sans/notosans-400.woff') format('woff'), /* Modern Browsers */
       url('../fonts/noto-sans/NotoSans-400.ttf') format('truetype'), /* Safari, Android, iOS */
}

@font-face {
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url('../fonts/noto-sans/notosans-700.woff2'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/noto-sans/notosans-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/noto-sans/notosans-700.woff') format('woff'), /* Modern Browsers */
       url('../fonts/noto-sans/NotoSans-700.ttf') format('truetype'), /* Safari, Android, iOS */
}

/* CHINESE FONT */

@font-face {
  font-family: 'Noto Sans SC';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url('../fonts/chinese/notosanssc-300.woff2'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/chinese/notosanssc-300.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/chinese/notosanssc-300.woff') format('woff'), /* Modern Browsers */
       url('../fonts/chinese/notosanssc-300.ttf') format('truetype'), /* Safari, Android, iOS */
}

@font-face {
  font-family: 'Noto Sans SC';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('../fonts/chinese/notosanssc-400.woff2'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/chinese/notosanssc-400.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/chinese/notosanssc-400.woff') format('woff'), /* Modern Browsers */
       url('../fonts/chinese/notosanssc-400.ttf') format('truetype'), /* Safari, Android, iOS */
}

@font-face {
  font-family: 'Noto Sans SC';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url('../fonts/chinese/notosanssc-700.woff2'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/chinese/notosanssc-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/chinese/notosanssc-700.woff') format('woff'), /* Modern Browsers */
       url('../fonts/chinese/notosanssc-700.ttf') format('truetype'), /* Safari, Android, iOS */
}

/* JAPANESE FONT */

@font-face {
  font-family: 'Noto Sans JP';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url('../fonts/japanese/notosansjp-300.woff2'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/japanese/notosansjp-300.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/japanese/notosansjp-300.woff') format('woff'), /* Modern Browsers */
       url('../fonts/japanese/notosansjp-300.ttf') format('truetype'), /* Safari, Android, iOS */
}

@font-face {
  font-family: 'Noto Sans JP';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('../fonts/japanese/notosansjp-400.woff2'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/japanese/notosansjp-400.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/japanese/notosansjp-400.woff') format('woff'), /* Modern Browsers */
       url('../fonts/japanese/notosansjp-400.ttf') format('truetype'), /* Safari, Android, iOS */
}

@font-face {
  font-family: 'Noto Sans JP';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url('../fonts/japanese/notosansjp-700.woff2'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/japanese/notosansjp-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/japanese/notosansjp-700.woff') format('woff'), /* Modern Browsers */
       url('../fonts/japanese/notosansjp-700.ttf') format('truetype'), /* Safari, Android, iOS */
}


//// Body

body {
  font-weight: 300;
}


//// Headings

h1, .h1 {
  font-size: 9rem;
  line-height: 1.1;
  
  @media (max-width: 991px) {
    font-size: 7rem;
  } 

  @media (max-width: 768px) {
    font-size: 5rem;
  }

  @media (max-width: 576px) {
    font-size: 4rem;
  }
}

h2, .h2 {
  font-size: 5rem;
  line-height: 1.1;

  @media (max-width: 991px) {
    font-size: 4rem;
  } 

  @media (max-width: 768px) {
    font-size: 3rem;
  }

  @media (max-width: 576px) {
    font-size: 2.3rem;
  }
}

.h2-3 {
  font-size: 4rem;
  line-height: 1.1;

  @media (max-width: 991px) {
    font-size: 3rem;
  } 

  @media (max-width: 768px) {
    font-size: 2.5rem;
  }

  @media (max-width: 576px) {
    font-size: 1.8rem;
  }
}

h3, .h3 {
  font-size: 3rem;
  line-height: 1.2;

  @media (max-width: 991px) {
    font-size: 3rem;
  } 

  @media (max-width: 768px) {
    font-size: 2.5rem;
  }

  @media (max-width: 576px) {
    font-size: 1.8rem;
  }
}

.h3-4 {
  font-size: 2.2rem;
  line-height: 1.2;

  @media (max-width: 991px) {
    font-size: 2.2rem;
  } 

  @media (max-width: 768px) {
    font-size: 1.8rem;
  }

  @media (max-width: 576px) {
    font-size: 1.6rem;
  }
}

h4, .h4 {
  font-size: 1.6rem;
  line-height: 1.4;

  @media (max-width: 768px) {
    font-size: 1.4rem;
  }

  @media (max-width: 576px) {
    font-size: 1.2rem;
  }
}

h5, .h5 {
  font-size: 1.3rem;
  line-height: 1.6;

  @media (max-width: 768px) {
      font-size: 1.2rem;
  }

  @media (max-width: 576px) {
      font-size: 1.1rem;
  }
}