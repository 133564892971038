////// AnyDesk Navs

//// Nav Pills

.nav-pills {
  margin-bottom: 3rem;
  border: 2px solid $gray-medium;
  padding: 15px;

  .nav-link {
    color: $body-color;
    text-decoration: none;
    border: 3px solid transparent;
    border-radius: 9999px;
    margin-bottom: 5px;

    &:last-child {
      margin-bottom: 0;
    }

    &:hover {
      opacity: 1;
      color: $primary-light;
      border: 3px solid $primary-light;
    }

    &.active {
      background-color: white;
      border: 2px solid $primary;
      color: $primary;
    }
  }
}

//// License Navigation

#licenseNav {
  .nav-tabs {
    border: none;
    margin-bottom: -20px;

    .nav-item {
      padding: 0;
      font-size: .9rem;
      margin-right: 5px;
      margin-top: 8px;

      @media (min-width: 768px) {
        padding: 0 15px;
        font-size: 1em;
        margin-right: 0;
      }

      &:last-child {
        margin-right: 0;
      }

      .nav-link {
        @extend .any-boxshadow-dark;
        transition: none;
        background: white;
        color: $gray-font;
        padding: 1rem;
        border-radius: 6px;
        border: none;
        font-size: 1.1rem;

        @media (min-width: 768px) {
          padding: .8rem;
          font-size: 1.5rem;
        }

        &.active  {
          box-shadow: none;
          background: $primary-orange-gradient-bg;
          color: $white;
          border: none;
        }

        &:focus  {
          @extend .any-boxshadow-dark;
        }

        &:not(.active):hover {
          background: $primary-orange-gradient-bg;
          color: white;
        }
      }
    }
  }

  .tab-content {
    border-radius: 6px;
  }
}

//// Icon Navigation

#iconNav {
  border: none;

  .nav-item {
    width: 24%;
    margin-right: 1%;
    margin-bottom: 1%;
    border-radius: 5px;
    overflow: hidden;
    
    @media (min-width: 992px) {
      width: 10.045%;
      margin-right: 1.2%;
      margin-bottom: 0;
    }

    &:last-child {
      margin-right: 0;
    }

    a {
      text-decoration: none;
    }

    .nav-link {
      text-align: center;
      border: 3px solid transparent;
      border-radius: 6px;
      padding: 0;

      &:hover, &.active {
        border: 3px solid $primary;
        border-radius: 6px;
        border-image: linear-gradient(45deg, $primary,$orange) 1;

        p {
          @extend .primary-orange-gradient;
        }
      }

      img {
        @extend .any-transition-fade;
        max-width: 80px;
        padding: 10px 10px 0 10px;
        height: auto;
        display: block;
        margin: 0 auto;

        @media (min-width: 576px) {
          max-width: 100px;
          padding: 15px 15px 0 15px;
        }
      }

      .iconNavThankYouImg {
        max-width: 90px;
        padding-top: 0.5rem;
      }

      p, span {
        @extend .any-transition-fade;
        font-weight: 300;
      }
    }
  }

  a.nav-link {
  	color: $gray-font;
  }
}


#iconNavSmall {
  border: none;

  .nav-link {
    text-align: center;
    border: 3px solid transparent;
    border-radius: 25px;
    padding: 0;

    &:hover, &.active {
      border: 3px solid $primary;
      border-radius: 25px;

      p {
        color: $primary;
      }
    }

    img {
      @extend .any-transition-fade;
      max-width: 80px;
      padding: 10px 10px 0 10px;
      height: auto;
      display: block;
      margin: 0 auto;

      @media (min-width: 576px) {
        max-width: 100px;
        padding: 15px 15px 0 15px;
      }
    }

    p, span {
      @extend .any-transition-fade;
      font-weight: 300;
    }
  }

  .nav-item {
    width: 32.1%;
    margin-right: 1.2%;
    margin-bottom: 1%;

    @media (min-width: 992px) {
      width: 9.1%;
      margin-right: 1%;
      margin-bottom: 0;

      &:last-child {
        margin-right: 0;
      }
    }

    a {
      text-decoration: none;
    }
  }

  a.nav-link {
    color: $gray-font;
  }
}