
//// AnyDesk Alerts


.alert {
  text-align: center;
  padding: 1rem 1.25rem 0.75rem;

  a {
    text-decoration: none;
  }
}

.alert-success {
  a {
    color: $green;
    border-bottom: 1px solid $green;

    &:hover {
      border-bottom: none;
    }
  }
}

.any-alertWarning {
  text-align: center;
  margin-bottom: 0;
  border-radius: 0;
  padding: 0.1rem;
  font-size: 0.9rem;

  button {  
    opacity: 1;

    &:hover {
      @extend .any-transition-fade;
      opacity: .6;
    }
  
    span {
      background-image: url(../../_static/img/icons/streamline/interface-essential/form-validation/close.svg);
      background-repeat: no-repeat;
      width: 15px;
      height: 15px;
      float: right;
      margin-top: 7px;
    }
  }
  span {
    padding-left: 0.2rem;
    
    @media (min-width: 768px) {
      padding-left: 0;
    }
  }

  .close {
      padding: 0 0.3rem 0 0;

    @media (min-width: 768px) {
      padding: 0 1rem 0 0;
    }
  }
}