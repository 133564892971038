//// AnyDesk Layout File: It contains all Layout Code

//// Graphics

//// Overlay

.any-overlay {
    background: $bg-color-black-transparent;
    position: absolute;
    top:0;
    left:0;
    width: 100%;
    height: 100%;
}

.any-overlay-lg {
    @media (min-width: 992px) {
        background: transparent;
    }
}

.any-overlay-light {
    @extend .any-overlay;
    background: $bg-color-black-transparent-light;
}

.any-overlay-white {
    @extend .any-overlay;
    background: $bg-color-white-transparent;
}

.any-overlay-white-light {
    @extend .any-overlay;
    background: $bg-color-white-transparent-light;
}

.any-overlay-rightHalf {
    @extend .any-overlay;
    width: 0;
    margin-left: 0;

    @media (min-width: 992px) {
        width: 50%;
        margin-left: 50%;
    }
}

.any-overlay-black-red-hover {
    background: linear-gradient(221deg, rgba(239,68,59,0.65) 0%, rgba(0,0,0,0.65) 100%);
    @extend .any-transition-fade-secondary;

    &:hover {
        background: $bg-color-black-transparent;
    }
}

.any-overlay-black-purple {
    @extend .any-overlay;
    background: linear-gradient(221deg, rgba(97,82,160,0.65) 0%, rgba(0,0,0,0.65) 100%);
}

.any-overlay-black-purple-hover {
    background: linear-gradient(221deg, rgba(97,82,160,0.65) 0%, rgba(0,0,0,0.65) 100%);
    @extend .any-transition-fade-secondary;

    &:hover {
        background: $bg-color-black-transparent;
    }
}

.any-overlay-black-magenta-hover {
    background: linear-gradient(221deg, rgba(172,47,128,0.65) 0%, rgba(0,0,0,0.65) 100%);
    @extend .any-transition-fade-secondary;

    &:hover {
        background: $bg-color-black-transparent;
    }
}

//// Quotes

.any-quote-wrapper {
    position: relative;

    .any-quote {
        position: absolute;
        font-size: 4rem;
        line-height: 0;
        color: $primary;
    }

    .any-quote-left {
        @extend .any-quote;
        left: -10px;
        top: -10px;
    }

    .any-quote-right {
        @extend .any-quote;
        right: -10px;
        bottom: -10px;
    }
}

