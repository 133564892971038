////// AnyDesk Cards

//// Standard Card

.card {
  border: 1px solid $gray-medium;
  border-radius: 0;
}


//// Card Accordion (Light with Navigation Points)

.card-accordion {
  .card { 
    background-color: transparent;
    width: 100%; 
    border: none;

    .card-header {
      cursor: pointer;
      font-weight: 700;
      border-bottom: none;
      background-color: transparent;
      padding: .75rem 2rem .75rem 0;

      @media (min-width: 992px) {
        padding: 1.25rem 2rem 1.25rem 0;
      }

      &:hover {
        @extend .any-transition-fade;
        opacity: 1;
      }

      &.collapsed {
        background-color: transparent;

        .card-link {
          color: $body-color;
        }
      }

      .accordion-icon { 
        position: absolute;
        top: 15px;
        right: 5px;
        font-size: 20px;
        color:white;
        -webkit-animation: minus 0.5s;
        background-repeat: no-repeat;
        width: 20px;
        height: 20px;
        display: block;
        margin-top: 10px;
      }

      .accordion-icon-center {
        font-size: 20px;
        color:white;
        -webkit-animation: minus 0.5s;
        background-repeat: no-repeat;
        width: 20px;
        height: 20px;
        display: inline-block;
        margin-top: 10px;
        margin-left: 5px;
      }

      .card-link {
        color: $primary;
        text-decoration: none;
        font-size: 1.2rem;
        border-bottom: none;

        .collapsed {
          .accordion-icon, .accordion-icon-center {
            background-image: url(../../_static/img/icons/streamline/arrows-diagrams/arrows/arrow-down-1.svg);
          }
        }
  
        .expanded {
          .accordion-icon {
            background-image: url(../../_static/img/icons/streamline/arrows-diagrams/arrows/arrow-up-1-red.svg);
          }
          .accordion-icon-center {
            background-image: url(../../_static/img/icons/streamline/arrows-diagrams/arrows/arrow-up-1.svg);
          }
        }
  
        &:hover {
          opacity: 1;
        }

        .h3-4 {
          color: $body-color !important;
        }
      }
    }
    
    .card-body {
      background-color: transparent;
      padding: 0 0 2rem 0;

      @media (min-width: 992px) {
        padding: 0 0 1.5rem 0;
      }

      .nav-link {
        display: block;
        width: 100%;
        padding: 0.5rem 0;
        line-height: 1.2rem;
        color: $body-color;
        text-decoration: none;
        border-bottom: none;

        @media (min-width: 576px) {
          line-height: 1.4rem;
        }

        &:hover {
          text-decoration: none;
          color: $primary;
          opacity: 1;
        }
      }
    }
  }

  @keyframes minus {
    from {
        transform:rotate(0deg);
    }
    to {
        transform:rotate(360deg);
    }
  }

  [aria-expanded="false"] > .expanded, [aria-expanded="true"] > .collapsed {
    display: none;
  }
}


//// Card Accordion for Dark Background

.card-accordion-dark {
  @extend .card-accordion;
  @extend .bg-color-gray-darker;

  .card { 
    .card-header {
      &.collapsed {
        .card-link {
          color: white;
        }
      }

      &.initial-load {
        .card-link {
          color: white;
        }
      }

      .card-link {
        .collapsed {
          .accordion-icon {
            background-image: url(../../_static/img/icons/streamline/arrows-diagrams/arrows/arrow-down-1-white.svg);
          }
        }
      }
    }
    
    .card-body {
      .nav-link {
        color: white;
      }
    }
  }
}

//// Card Accordion NEW NEW Design

.card-accordion-info {
  text-align: left !important;

  .card {
    background-color: rgba(0, 0, 0, .1);
    border-radius: 6px;
    width: 100%;
    border: none;
    margin-bottom: 12px;
    padding: 0 20px;

    @media (min-width: 576px) {
      padding: 0 45px;
    }

    .card-header {
      .card-link {
        color: $body-color;

        h5 {
          color: $body-color !important;
        }

        .info-icon {
          position: absolute;
          top: 15px;
          right: 40px;
          width: 20px;

          @media (min-width: 576px) {
            top: 16px;
          }

          @media (min-width: 768px) {
            top: 18px;
          }

          @media (min-width: 992px) {
            top: 27px;
          }
        }
      }
    }

    .card-body {
      padding-bottom: 10px;
    }
  }
}


//// Card Accordion with red number icons

.card-accordion-numbers {
  .card {
    .card-header {
      .circle {
        font-weight: 400;
        align-items: center;
        margin-right: 20px;
        width: 45px;
        height: 45px;
        padding: 0px 14px 0px 14px;
        border-radius: 22.5px;
        border: 2px solid $primary;
        color: $primary;
        font-size: 20px;
      }

      .circle-four {
        padding: 0px 14px 0px 13px;
      }
    }
  }
}
