////// AnyDesk modals

//// Modal Jump to top Bug Fix

body.modal-open {
  overflow: visible;
  position: absolute;
  width: 100%;
  height: 100%;
  padding-right: 0 !important;
}


//// Standard modals

.modal {
  // Remove scrollbar
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE 10+ */

  .modal-dialog-centered {
    @media (max-width: 767px) {
      min-height: 0;
    }
  }

  .modal-custom {
    background-color: $white !important;
    padding: 10px 20px;
  }

  .modal-content {
    border-radius: 10px;
    border: none;
    background-color: black;
    margin-top: 25px;

    @media (min-width: 768px) {
      margin-top: 0;
    }

    .modal-header {
      background-color: white;
      padding: 1rem;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      border-bottom-left-radius: 0;
      border-bottom-left-radius: 0;
      border: none;
      justify-content: center;

      .modal-title {
        font-size: 1.5rem;
        line-height: 2rem;
        font-weight: 300;
      }

      button {
        position: absolute;
        right: 0;
        top: -37px;
        opacity: 1;

        @media (min-width: 576px) {
          right: -37px;
        }

        &:hover {
          @extend .any-transition-fade;
          opacity: .6;
        }

        span {
          background-image: url(../../_static/img/icons/streamline/interface-essential/form-validation/close-white.svg);
          background-repeat: no-repeat;
          width: 30px;
          height: 30px;
          float: right;
        }
      }

      .close {
        color: $white;
        font-size: 2rem;
        line-height: 1.9rem;
        text-shadow: none;

        @media (min-width: 576px) {
          font-size: 2.5rem;
          line-height: 2.3rem;
        }
      }
    }

    .modal-body {
      padding: 0.5rem;

      a {
        color: white;

        &:hover {
          color: $primary;
        }
      }

      .btn {
        &:hover {
          color: white;
        }
      }
    }

    .modal-footer {
      border: none;
      padding-top: 0;
    }
  }
}

.modal-contact {
  .modal-content {
    background-color: transparent;

    .modal-header {
      padding-top: 1.7rem;
      background-color: transparent;

      .modal-title {
        display: none;
      }

      button {
        span {
          background-image: url(../../_static/img/icons/streamline/interface-essential/form-validation/close-white.svg);
          width: 20px;
          height: 20px;
        }
      }

      .close {
        right: -15px;
        top: 15px;
        padding: 15px;
      }
    }

    .modal-body {
      background-color: black;
      border-radius: 10px;
    }
  }
}

.modal-demo {
  .modal-dialog {
    top: 20px;
  }
  .modal-content {
    background-repeat: no-repeat;
    background-color: transparent;
    height: 444px;
    width: 500px;
    border-radius: 0;

    .modal-header {
      background-color: transparent;

      .modal-title {
        display: none;
      }
    }
  }
}

.modal-link {
  height: 100%;
  width: 100%;
  display: block;
  border-bottom: 0px;
}

.modal-video {
  .modal-content {
    background-color: transparent;

    .modal-header {
      background-color: transparent;

      .modal-title {
        display: none;
      }

      .close {
        right: 0px;
        top: -12px;

        @media (min-width: 768px) {
          right: -45px;
        }
      }
    }

    .modal-body {
      padding: 0;
    }
  }
}

.modal-onload {
  background-color: rgba(0, 0, 0, .7);

  .modal-content {
    background-color: transparent;
    margin-top: 45px;

    @media (min-width: 768px) {
      margin-top: 0;
    }

    .modal-header {
      background-color: transparent;
      margin-bottom: -50px;
      z-index: 1000;

      .close {
        right: 0px;
        top: -12px;

        @media (min-width: 768px) {
          right: -45px;
        }
      }
    }

    img {
      width: 100%;
    }

    .modal-onload-desktop-images {
      display: none;

      @media (min-width: 576px) {
        display: block;
      }
    }

    .modal-onload-mobile-images {
      display: block;

      @media (min-width: 576px) {
        display: none;
      }
    }
  }
}

.modal-licenses {

  .modal-dialog {
    max-width: 800px;

    .modal-content {
      background: white;
      border-radius: 0;

      .modal-header {
        padding: 0;

        button {
          z-index: 1000;

          span {
            background-image: url(../../_static/img/icons/streamline/interface-essential/form-validation/close.svg);
            width: 20px;
            height: 20px;
          }
        }

        .close {
          right: 15px;
          top: 15px;
          padding: 15px;
        }
      }
    }

    .modal-body {
      padding: 2em;

      @media (min-width: 1200px) {
        padding: 3.5rem;
      }

      a {
        &:hover {
          color: white;
        }
      }

      .any-licenseModal-oldprice {
        text-decoration: line-through $primary;
        font-size: 2.5rem;
        line-height: 1.2;
      }

      .any-licenseModal-originalprice {
        font-size: 3.5rem;
        color: $primary;
        line-height: normal;
      }

      li {
        padding-bottom: 10px;
      }
    }
  }
}

.modal-contact-form {
  background: rgba(255,255,255,0.1) 0% 0% no-repeat padding-box;
  opacity: 1;
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
  padding-left: 15px;
  padding-top: 15px;
  padding-bottom: 15px;

  .modal-content {
    background-color: transparent;
    color: white;

    .modal-header {
      background-color: rgba(0, 0, 0, .6) !important;
      padding-top: 20px;

      .modal-title {
        font-weight: bold;
        font-size: 28px;
      }

      .close {
        top: 20px;
        right: 20px;
      }
    }

    .modal-body {
      background-color: rgba(0, 0, 0, .6) !important;
      padding-bottom: 42px;

      form {
        color: $body-color;

        p {
          color: $body-color;
        }
      }

      a {
        color: $primary;
      }

      p {
        color: white;
      }
    }
  }
}

//// Add XL Modal

@media (min-width: 768px) {
  .modal-xl {
    width: 90%;
    max-width: 1200px;
  }
}

//// Add XXL Modal

.modal-xxl {
  width: 100%;
  max-width: 1500px;
  margin: 0 auto;
}


//// Remove animations for "ad_animate.scss"

.modal.fade {
  opacity: 1;
}

.modal.fade .modal-dialog {
  -webkit-transform: translate(0);
  -moz-transform: translate(0);
  transform: translate(0);
}


//// Remove Scrollbar

.modal::-webkit-scrollbar {
  display: none;
}




