////// AnyDesk Navbar

//// Main Navigation

// Navbar

.container-nav-bg {
  background-color: white;
}

.container-white {
  background-color: white;
}

.navbar {
  font-weight: 300;
  font-size: 1rem;
  padding: 1rem 0 1rem 0;
  flex-wrap: nowrap;

  @media (min-width: 992px) {
    padding: 0 0 1rem 0;
  }

  a {
    text-decoration: none;
    border-bottom: none;
  }

  .navbar-logo {
    &:hover {
      opacity: 1;
    }

    display: inline-flex;

    .any-logo {
      height: 30px;
      z-index: 1001;
    }

    .partner-logo {
      height: 20px;
    }

    .partner-text {
      white-space: nowrap;
      font-weight: 400;
      font-size: 1.5rem;
      color: white;

      @media (min-width: 992px) {
        font-size: 2.0rem;
      }
    }
  }

  .navbar-toggler {
    z-index: 1002;
    border: none;
    padding: 0;

    .icon-bar {
      background: $body-color;
      display: block;
      width: 30px;
      height: 1px;
      margin: 7px 0;
      transition: all 0.2s;

      &:nth-of-type(1) {
        transform: rotate(45deg);
        transform-origin: 2px 8px;
      }

      &:nth-of-type(2) {
        opacity: 0;
        filter: alpha(opacity=0);
      }

      &:nth-of-type(3) {
        transform: rotate(-45deg);
        transform-origin: 10px -2px;
      }
    }

    &.collapsed {

      .icon-bar {
        background: $body-color;

        &:nth-of-type(1) {
          transform: rotate(0);
        }

        &:nth-of-type(2) {
          opacity: 1;
          filter: alpha(opacity=100);
        }

        &:nth-of-type(3) {
          transform: rotate(0);
        }
      }
    }

    &:focus {
      outline: 0;
    }
  }

  .navbar-collapse {
    position: absolute;
    width: 100%;
    top: 90px;
    z-index: 1002;
    @extend .any-transition-fade-secondary;

    @media (min-width: 992px) {
      position: initial;
      width: auto;
      margin-left: 45px;
    }

    &.collapsing {
      @include transition($transition-fade);
    }

    .navbar-nav {
      background-color: white;
      /*padding-top: .5rem;
      padding-bottom: .5rem;*/

      @media (min-width: 992px) {
        background-color: transparent;
        border: none;
      }

      .nav-item {
        position: relative;
        border-bottom: 1px solid $gray-medium;
        white-space: nowrap;

        @media (min-width: 992px) {
          border-bottom: none;
        }
      }

      .nav-item-selected {
        .nav-link {
          color: $primary;
          font-weight: 400;

          @media (min-width: 992px) {
            color: $body-color;
            border-bottom: 3px solid transparent;
            border-image: $primary-orange-gradient-border;
            border-image-slice: 1;
          }
        }
      }

      .nav-item-last-child {
        .nav-link {
          @media (min-width: 992px) {
            margin-right: 0;
          }
        }

        .dropdown-menu {
          right: -15px;

          @media (min-width: 1200px) {
            right: -23px;
          }
        }
      }

      .nav-link {
        color: $body-color;
        margin-right: 0;
        margin-left: 0;
        padding: .6rem 1rem;
        font-weight: 400;

        @media (min-width: 576px) {
          padding: .6rem 1.5rem;
        }

        @media (min-width: 992px) {
          color: $body-color;
          margin: 15px 9px;
          padding-right: 0;
          padding-left: 0;
          padding-top: .25rem;
          padding-bottom: .25rem;
          border-bottom: 3px solid transparent;
        }

        @media (min-width: 1200px) {
          margin-right: 9px;
          margin-left: 9px;
        }

        @media (min-width: 1265px) {
          margin-right: 16px;
          margin-left: 16px;
        }

        &:hover {
          background-color: $gray-light;
          transition: top 0.4s ease-in-out;
          -webkit-transition: all 1s ease;
          -moz-transition: all 1s ease;
          -o-transition: all 1s ease;
          transition: all 1s ease;
          opacity: 1;

          @media (min-width: 992px) {
            background-color: transparent;
            border-bottom: 3px solid $red-light;
          }
        }

        &::after {
          content: "";
          display: inline-block;
          background-repeat: no-repeat;
          float: right;
          width: 15px;
          height: 15px;
          margin-top: 6px;

          @media (min-width: 992px) {
            display: none;
          }
        }

        &.dropdown-toggle {
          &:hover {
            @media (min-width: 992px) {
              border-bottom: 3px solid $red-mega-light;
            }
          }
        }

        .menu-icon-arrow-down-black, .menu-icon-arrow-down-white {
          @extend .any-transition-fade;

          @media (max-width: 991px) {
            display: none !important;
          }
        }
      }

      .nav-link-arrow-right {
        &::after {
          background-image: url(../../_static/img/icons/streamline/arrows-diagrams/arrows/arrow-right-1.svg);
        }
      }

      .dropdown-menu {
        background-color: white;
        width: auto;
        margin: 0;

        @media (min-width: 992px) {
          padding: 25px 30px;
          left: 50%;
          transform: translate(-50%, 0);
        }

        &.dropdown-menu-navbar1 {
          @media (min-width: 992px) {
            left: -200px;
            transform: translate(0, 0);
          }

          @media (min-width: 1460px) {
            left: -225px;
          }
        }

        &.dropdown-menu-navbar4 {
          @media (min-width: 992px) {
            left: auto;
            right: 50%;
            transform: translate(36%, 0);
          }

          @media (min-width: 1200px) {
            transform: translate(52%, 0);
          }
        }

        .dropdown-item {
          white-space: normal;
          min-width: 172px;

          @media (min-width: 1050px) {
            min-width: 180px;
          }

          @media (min-width: 1100px) {
            min-width: 195px;
          }

          @media (min-width: 1150px) {
            min-width: 210px;
          }

          @media (min-width: 1200px) {
            min-width: 225px;
          }

          @media (min-width: 1250px) {
            min-width: 236px;
          }
        }
      }
    }
  }
}


// Navbar Light

.navbar-light {
  .navbar {
    .navbar-logo {
      .any-logo-black {
        display: none;
      }

      .any-logo-white {
        display: inline-block;
      }
    }

    .navbar-collapse {
      .navbar-nav {
        .nav-link {
          color: $body-color;

          @media (min-width: 992px) {
            color: white;
          }

          .menu-icon-arrow-down-black {
            display: none;
          }

          .menu-icon-arrow-down-white {
            display: inline-block !important;
            margin-left: 5px;

            @media (max-width: 992px) {
              display: none !important;
            }
          }
        }
      }
    }

    .navbar-toggler {
      &.collapsed {
        .icon-bar {
          background: white;
        }

        ~ .any-logo-black {
          display: inline-block !important;
        }

        ~ .any-logo-white {
          display: none !important;
        }
      }
    }
  }

  .change-theme-text {
    color: #747474;
  }

  .container-nav-bg {
    background-color: transparent;
  }

  .container-white {
    background-color: transparent;
  }

  &.nav-up, &.nav-down {
    @media (min-width: 992px) {
      .navbar {
        .navbar-logo {
          .any-logo-black {

            display: inline-block !important;
          }

          .any-logo-white {
            display: none !important;
          }
        }

        .navbar-collapse {
          .navbar-nav {
            .nav-link {
              .menu-icon-arrow-down-white {
                display: none !important;
              }
            }
          }
        }
      }

      .nav-link {
        color: $body-color !important;

        .menu-icon-arrow-down-white {
          display: none;
        }

        .menu-icon-arrow-down-black {
          display: inline-block !important;
        }
      }

      .nav-partner-text {
        color: $body-color;
      }

      .change-theme-text {
        color: $body-color;
      }
    }

    .any-logo-black-made {
      display: inline !important;
    }

    .any-logo-white-made {
      display: none !important;
    }
  }

  .any-logo-black-made {
    display: none !important;
  }

  .any-logo-white-made {
    display: inline !important;
  }
}

.navbar-gray {
  .container-white, .container-nav-bg {
    background-color: $gray-light !important;
  }
}


// Navbar onlyLogo

.container-nav-onlylogo {
  .navbar {
    height: 124.86px;

    .navbar-collapse, .navbar-toggler {
      display: none !important;
    }
  }
}


// IE 10 + 11 fix
@media all and (-ms-high-contrast: none) {
  .navbar-collapse {
    right: 0;
    top: 20px;
  }

  .navbar-collapse .dropdown-toggle {
    margin-right: 3px !important;
  }

  .navbar-nav {
    margin-right: -15px;

    @media (max-width: 991px) {
      margin-top: 71px;
    }
  }

  .navbar-toggler {
    position: absolute;
    top: 25px;
    right: 0;
  }

  .nav-item-last-child .dropdown-menu {
    right: -15px !important;
  }

  *::-ms-backdrop, .navbar-collapse {
    right: 0;
    top: 20px;
  }

  *::-ms-backdrop, .navbar-collapse .dropdown-toggle {
    margin-right: 3px !important;
  }

  *::-ms-backdrop, .navbar-nav {
    margin-right: -15px;

    @media (max-width: 991px) {
      margin-top: 71px;
    }
  }

  *::-ms-backdrop, .navbar-toggler {
    position: absolute;
    top: 25px;
    right: 0;
  }

  *::-ms-backdrop, .nav-item-last-child .dropdown-menu {
    right: -15px !important;
  }
}


// Additional Navbar Scroll Appearing

header {
  position: relative;
  width: 100%;
  z-index: 99;

  @media (min-width: 992px) {
    position: fixed;
    top: 0;
    transition: top 0.2s ease-in-out;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
  }
}

.nav-up {
  @media (min-width: 992px) {
    top: -174px;
  }
}

.nav-up, .nav-down {
  @media (min-width: 992px) {
    background-color: white;
  }
}


//// Navbar BG

.navbar-bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: white;
  display: none;
  z-index: 1001;
}

.any-logo-made {
  width: 120px;
  @media (min-width: 768px) {
    width: 142px;
  }
}

.any-logo-made-florida {
  width: 120px;
}


// IE 11 Hacks
select::-ms-expand {
  display: none;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .navbar .navbar-logo {
    display: block;
    width: 165px;
  }

  .navbar .navbar-collapse {
    position: relative;
    top: 0;
  }
}
