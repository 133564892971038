////// AnyDesk Reboot

//// Main Link class

a, .any-link {
  @extend .any-transition-fade;
  cursor: pointer;
  text-decoration: none;
  border-bottom: 1px solid $primary;
  font-weight: 400;
  
  &:hover {
    @extend .any-transition-fade;
    text-decoration: none;
    border-bottom: none;
  }
}

.any-link {
  &:hover {
    border-bottom: 1px solid transparent !important;
  }
}

.any-link-purple {
  border-bottom: 1px solid $purple-light;
}

.any-link-white {
  color: white;
  border-bottom: 1px solid white;

  &:hover {
    color: white;
  }
}


// Link Fix

a:not([href]):not([tabindex]) {
  color: $primary;

  &:hover {
    color: $primary;
  }
}


// Link with border on Hover

.any-link-border {
  border: 3px solid transparent;
  border-radius: 6px;
  font-weight: 300;
  color: $body-color;
  text-decoration: none;

  &:hover {
    border: 3px solid $primary;
    border-radius: 6px;
    border-image: linear-gradient(45deg, $primary,$orange) 1;
    text-decoration: none;
    color: $body-color;
  }
}


// Link Icons

.any-link-icon-right {
  margin-left: 4px;
  margin-bottom: 2px;
}

.any-link-icon-left {
  margin-right: 4px;
  margin-bottom: 2px;
}