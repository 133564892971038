////// AnyDesk Dropdown

//// Main Dropdown Navbar

.dropdown {
  .dropdown-toggle {
    &::after {
      border: none;
      background-image: url(../../_static/img/icons/streamline/arrows-diagrams/arrows/arrow-down-1.svg);
      margin-top: 5px;
  
      @media (min-width: 992px) {
        display: none;
      }
    }
  }
}

.language-select {
  .dropdown-toggle {
    &::after {
      background-image: none;
      margin-left: 0;
    }

    .flag-icon {
      margin-left: 3px;
    }
  }
}

.dropdown-menu {
  background-color: white;
  margin: 0 0 .5rem 0;
  left: auto;
  right: -1px;
  border: none;
  border-top: 1px solid $gray-medium;
  border-radius: 0;
  padding: 1.5rem;

  @media (min-width: 992px) {
    border: 1px solid $gray-medium;
    margin-right: 15px;
    margin-left: 15px;
    border-radius: 6px;
    padding: 35px 40px;
    right: auto;
    left: -20px;
  }

  @media (min-width: 1200px) {
    margin-right: 22px;
    margin-left: 22px;
  }

  .dropdown-category {
      margin: 0 0 1rem 0;
      width: 100%;
      padding: 0 0 .5rem 0;
      color: $primary;
      border-bottom: 1px solid $gray-medium;
      font-weight: 400;
  }

  .dropdown-item {
    color: $body-color;
    font-weight: 400;
    padding: .25rem 0;
  
    &:hover {
      background-color: transparent;
      color: $primary;
      opacity: 1;
    }
  }
}


// Dropdown Animation

.show > .dropdown-menu {
  @extend .any-transition-fade-secondary;
}


// Bootstrap Select Dropdown Scrollbar

.bootstrap-select {
  .dropdown-menu {
    max-height: 400px;

    div {
      width: 134px !important;
      overflow: hidden auto;

      ul {
        width: 134px !important;
        min-width: 134px !important;
      }
    }
  }
}