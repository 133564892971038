//// AD-WWW - stuff we need only need for our main website

// MODULE: Pricing table

.any-module-pricingTable {
  padding: 20px 30px;

  .any-module-pricingTable-ribbon {
    text-align: center;
    margin-top: -37px;

    img {
      width: 100%;
      max-width: 350px;
    }
  }

  .any-module-pricingTable-heading {
    height: 30px;
    align-items: center;
    justify-content: center;
    display: flex;
    font-size: 1.5rem;
    font-weight: bold;
  }

  .any-module-pricingTable-heading-primary {
    @extend .any-module-pricingTable-heading;
    color: $primary;
    line-height: 1;
    text-align: center;
  }

  .any-module-pricingTable-price {
    font-size: 2.5rem;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 1.8rem;
    color: $body-color;
    padding: 10px;

    @media (min-width: 576px) {
      height: 130px;
    }

    @media (min-width: 992px) {
      font-size: 2.5rem;
      padding: 0;
    }

    @media (min-width: 1200px) {
      font-size: 3rem;
      padding: 10px;
    }

  }

  .any-module-pricingTable-originalprice {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    line-height: 4.5rem;
  }

  .any-module-pricingTable-oldprice {
    text-decoration: line-through;
    text-align: center;
    height: 25px;
  }

  .any-module-pricingTable-valid {
    height: 13px;
    align-items: center;
    justify-content: center;
    display: flex;
    color: $gray-font;
    display: none;
  }

  .any-module-pricingTable-description {
    font-weight: 400;
    height: auto;
    border-bottom: 1px solid $gray-light;
    margin-bottom: 10px;

    @media (min-width: 768px) {
      height: 180px;
      margin-bottom: 0;
    }

    @media (min-width: 1200px) {
      height: 130px;
      margin-bottom: 0;
    }
  }

  .any-list {
    font-weight: 400;
    margin-bottom: 3rem;
  }

  .any-module-pricingTable-featureList {
    padding-left: 1rem;

    @media (min-width: 1200px) {
      padding-left: 7rem;
    }
  }

  .any-module-pricingTable-ribbonValue {
    position: relative;

    img {
      width: 100%;
      max-width: 40px;
      position: absolute;
      right: -10px;
      top: -20px;
    }
  }
}


// Pricing Table Highlighted

.any-module-pricingTableHighlighted {
  border: 2px solid $primary;
  @extend .any-boxshadow-dark;
}


// Pricing Table Mini

.any-module-pricingTable-mini {

  .any-module-pricingTable-heading {
    font-size: 2rem;
  }

  .any-module-pricingTable-description {
    text-align: center;
    margin-top: 20px;
    margin-bottom: 20px;
  }
}


// MODULE:  Service Table 

.any-module-servicesTable {
  width: 100%;

  td {
    width: 33%;
  }

  .any-module-servicesTable-heading {
    position: relative;
    border: 3px solid $primary;
    margin: 0 3px 15px;
    padding: 15px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  .any-module-servicesTable-content {
    border-right: 5px solid $white;

    &:last-child {
      border-right: none;
    }

    p {
      margin: 1rem 0.3rem;
    }
  }
}


// MODULE: Ranking Table

.any-module-rankingTable {
  .any-module-rankingTable-name {
    position: relative;

    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      right: 60px;
      height: 0.8em;
      border-top: 1px solid $body-color;
      width: 120px;

      @media (min-width: 576px) {
        width: 220px;
      }

      @media (min-width: 768px) {
        width: 100px;
      }

      @media (min-width: 1200px) {
        width: 170px;
      }

      @media (min-width: 1400px) {
        width: 220px;
      }
    }

    span {
      padding-right: 10px;
    }
  }

  .any-module-rankingTable-nameHighlighted {
    @extend .any-module-rankingTable-name;

    &::after {
      height: 1em;
      border-top: 5px solid $primary;
      z-index: 1;
    }
  }

  .any-module-rankingTable-pts {
    position: absolute;
    right: 0;
    top: 0;
    padding-left: 10px;
  }
}

.any-module-rankingTableWhite {
  @extend .any-module-rankingTable;

  .any-module-rankingTable-name {
    &::after {
      content: "";
      border-top: 1px solid $white;
    }
  }

  .any-module-rankingTable-nameHighlighted {
    &::after {
      height: 1em;
      border-top: 5px solid $primary;
      z-index: 1;
    }
  }
}


// MODULE: Slick Slider

.any-slider-slick-col {
  padding-left: 0;
  padding-right: 0;
}

.any-slider-slick {
  .any-slider {
    font-size: 14px;
    transition: all 300ms ease;
    text-align: center;
  }

  .slick-center {
    .any-slider {
      font-size: 14px;
    }
  }

  .slick-slide {
    display: inline-block !important;
    vertical-align: middle !important;
    float: none !important;

    &:focus {
      outline: none;
    }
  }

  .slick-arrow {
    width: 60px;
    height: 60px;
    z-index: 1;

    @media (min-width: 768px) {
      width: 80px;
      height: 80px;
    }

    @media (min-width: 1200px) {
      width: 120px;
      height: 120px;
    }

    &::before {
      content: "";
      display: inline-block;
      background-repeat: no-repeat;
      width: 35px;
      height: 100%;
      opacity: 1;
      margin-top: -60px;


      @media (min-width: 992px) {
        margin-top: 22px;
        width: 50px;
      }

      @media (min-width: 1200px) {
        width: 70px;
      }
    }
  }

  .slick-prev {
    left: -5px;

    @media (min-width: 992px) {
      left: 15px;
    }

    &::before {
      background-image: url(../../_static/img/icons/streamline/arrows-diagrams/arrows/arrow-left-1-white.svg);
    }
  }

  .slick-next {
    right: 0;

    @media (min-width: 992px) {
      right: 15px;
    }

    &::before {
      background-image: url(../../_static/img/icons/streamline/arrows-diagrams/arrows/arrow-right-1-white.svg);
    }
  }
}

.slick-dots {
  li {
    &.slick-active {
      button {
        &:before {
          background-color: $primary;
        }
      }
    }

    button {
      &:before {
        font-family: none;
        content: '' !important;
        background-color: $gray;
        border-radius: 50%;
        width: 15px !important;
        height: 15px !important;
        opacity: .7 !important;
      }
    }
  }
}

.any-slider-slick-images {
  @extend .any-slider-slick;

  .slick-list {
    padding: 0 !important;

    @media (min-width: 768px) {
      padding: 0 !important;
    }
  }

  .any-slider {
    min-height: 100%;

    img {
      width: 99%;
      margin: 0 auto;
    }
  }
}

.any-slider-slick-images-text {
  @extend .any-slider-slick;

  .slick-list {
    padding-bottom: 0 !important;
  }

  .any-slider {
    position: relative;
    background-size: cover;
    background-position: center;
    color: $white;
    height: 300px;

    @media (min-width: 768px) {
      height: 400px;
      margin-right: 10px;
    }

    img {
      display: inline-block;
    }
  }

  .slick-arrow {
    bottom: -130px;
    top: auto;

    &:before {
      width: 30px;
      margin-top: 0;
    }
  }

  .slick-next {
    right: 20px;
    width: 35px;
  }

  .slick-prev {
    left: 20px;
    width: 35px;
  }
}

.any-slider-slick-images-myad {
  @extend .any-slider-slick;

  .slick-list {
    padding: 0 !important;

    @media (min-width: 768px) {
      padding: 0 !important;
    }
  }

  .any-slider {
    min-height: 100%;

    img {
      width: 99%;
      margin: 0 auto;
      border-radius: 6px;
    }
  }

  .slick-arrow {
    @media (max-width: 575px) {
      width: 30px;
    }

    &::before {
      margin-top: 25px;
      width: 25px;

      @media (min-width: 576px) {
        margin-top: 40px;
        width: 35px;
      }

      @media (min-width: 992px) {
        margin-top: 25px;
      }
    }
  }

  .slick-next {
    right: -25px;
    height: 35px;

    @media (min-width: 576px) {
      right: -52px;
    }

    @media (min-width: 768px) {
      right: -65px;
    }

    @media (min-width: 1200px) {
      right: -85px;
    }
  }

  .slick-prev {
    left: -25px;
    height: 35px;

    @media (min-width: 576px) {
      left: -52px;
    }

    @media (min-width: 768px) {
      left: -65px;
    }

    @media (min-width: 1200px) {
      left: -85px;
    }
  }
}

.any-slider-slick-stories {
  @extend .any-slider-slick;
  margin-top: -114px;

  @media (min-width: 576px) {
    margin-top: -106px;
  }

  @media (min-width: 992px) {
    margin-top: 0;
  }

  .any-slider {
    position: relative;
    background-size: cover;
    background-position: center;
    color: $white;
    height: 600px;
    width: 100%;
    padding-top: 50px;

    @media (min-width: 992px) {
      height: 625px;
      padding-top: 75px;
    }

    @media (min-width: 1200px) {
      height: 650px;
      padding-top: 106px;
    }

    @media (min-width: 1500px) {
      width: 1400px;
      margin: 0 20px;
    }

    @media (min-width: 1600px) {
      width: 1500px;
    }

    .any-overlay {
      width: 100%;
      margin-left: 0;

      @media (min-width: 768px) {
        width: 70%;
        margin-left: 30%;
      }

      @media (min-width: 992px) {
        width: 50%;
        margin-left: 50%;
      }
    }

    .any-slider-content {
      position: relative;
      display: flex;
      align-items: center;
      float: right;
      width: 100%;

      @media (min-width: 768px) {
        width: 70%;
      }

      @media (min-width: 992px) {
        width: 50%;
      }
    }

    img {
      display: inline-block;
    }
  }

  .slick-dots {
    display: none !important;
    bottom: 28px;
  }

  #slick-slide00, #slick-slide02 {
    .any-slider {
      .any-overlay {
        width: 0;
        margin-left: 100%;
      }

      .any-slider-content {
        opacity: 0;
      }
    }
  }

  .slick-arrow {
    bottom: -65px;
    top: auto;

    @media (min-width: 768px) {
      bottom: -50px;
    }

    @media (min-width: 992px) {
      bottom: -10px;
    }

    @media (min-width: 1200px) {
      bottom: -50px;
    }

    @media (min-width: 1500px) {
      height: 100%;
    }

    &:before {
      width: 32px;

      @media (min-width: 1500px) {
        background: none;
      }
    }
  }

  .slick-next {
    right: 0;
  }

  .slick-prev {
    left: 0;
  }
}

.any-slider-slick-stories-testimonials {
  @extend .any-slider-slick;

  .any-overlay {
    display: none;
  }

  .any-slider {
    position: relative;
    margin: 0 !important;
    vertical-align: bottom !important;
    min-height: 400px;

    @media (min-width: 576px) {
      min-height: 290px;
    }

    @media (min-width: 992px) {
      min-height: 410px;
    }

    @media (min-width: 1200px) {
      min-height: 440px;
    }

    .any-slider-slick-center {
      @extend .any-transition-fade;
      position: absolute;
      bottom: 0;
      background: $bg-color-black-transparent;
      padding: 20px 40px;
      margin: 0 30px;
      display: flex;
      align-items: center;
      min-height: 300px;

      @media (min-width: 576px) {
        margin: 0 50px;
      }

      @media (min-width: 768px) {
        margin: 0 100px;
      }

      @media (min-width: 992px) {
        margin: 0 20px;
        padding: 0 40px;
        min-height: 400px;
      }

      @media (min-width: 1500px) {
        margin: 0 80px;
      }

      @media (min-width: 2500px) {
        margin: 0 100px;
      }

      p {
        font-size: 1rem;

        @media (min-width: 1200px) {
          font-size: 1.1rem;
        }
      }
    }
  }

  .any-slider[tabindex="-1"] {
    visibility: hidden;
  }

  .slick-current {
    .any-slider-slick-center {
      transform: scale(1.08);
    }
  }

  .any-slider-stay-big {
    transform: scale(1.08) !important;
  }

  .slick-dots {
    bottom: -100px;

    @media (min-width: 576px) {
      bottom: -110px;
    }

    @media (min-width: 768px) {
      bottom: -120px;
    }

    @media (min-width: 992px) {
      bottom: -130px;
    }
  }
}

.any-slider-slick-articles,
.any-slider-slick-onboarding,
.any-slider-slick-premium-onboarding,
.any-slider-slick-essentials-onboarding {
  @extend .any-slider-slick;

  .slick-list {
    padding: 0 !important;

    @media (min-width: 768px) {
      padding: 0 !important;
    }
  }

  .any-slider {
    min-height: 100%;
    padding: 0 15px;
    text-align: left;

    img {
      display: inline-block;
    }
  }
}

.any-slider-slick-reference-logos {
  @extend .any-slider-slick;

  .any-slider {
    min-height: 150px;
    align-items: center;
    justify-content: center;
    display: flex;

    img {
      width: 80%;
      margin: 0 auto;

      @media (min-width: 992px) {
        width: 70%;
      }
    }
  }
}

.any-slider-slick-testimonials {
  @extend .any-slider-slick;

  .any-slider-slick-center {
    max-width: 1000px;
    margin-left: auto;
    margin-right: auto;

    .testimonial-img-profile {
      display: inline;
      width: 140px;
      height: 140px;
      border: 2px solid $primary;
      margin-bottom: 1rem;
    }
  }

  .slick-list {
    padding-bottom: 40px !important;
  }
}

.any-slider-slick-features {
  @extend .any-slider-slick;

  .slick-list {
    padding-bottom: 80px;
  }

  .slick-dots {
    padding-left: 10px;
  }
}

.any-slider-slick-collaboration {
  @extend .any-slider-slick;

  .slick-list {
    padding-bottom: 80px;
  }
}

.any-slider-slick-namespace {
  @extend .any-slider-slick;

  .any-slider {
    height: auto;
  }

  @media (min-width: 992px) {
    .any-slider {
      height: 160px;
    }
  }

  .slick-arrow {
    width: 50px;
    height: 50px;
    position: absolute;
    top: 10px;
  }

  .slick-next {
    right: 0;
  }

  .slick-prev {
    left: 0;
  }

  .slick-dots {
    bottom: -35px;
    li {
      width: 6px !important;
      height: 6px !important;
      button {
        &:before {
          width: 6px !important;
          height: 6px !important;
        }
      }
    }
  }
  .slick-arrow {
    &::before {
      width: 30px;
    }
  }

  .slick-prev {
    &::before {
      background-image: url(../../_static/img/icons/streamline/arrows-diagrams/arrows/arrow-left-1-red.svg);
    }
  }

  .slick-next {
    &::before {
      background-image: url(../../_static/img/icons/streamline/arrows-diagrams/arrows/arrow-right-1-red.svg);
    }
  }
}

.any-slider-slick-client-testimonials {
  @extend .any-slider-slick;

  .any-slider {
    text-align: left;
    background-color: white;
    padding: 30px;
    margin: 0 50px;

    @media (min-width: 480px) {
      padding: 38px;
      margin: 0 10px;
    }

    @media (min-width: 768px) {
      margin: 0 20px;
    }
  }

  .slick-list {
    padding-top: 35px;
  }

  .slick-arrow {
    &::before {
      width: 40px;
      margin-top: 46%;
    }
  }

  .slick-prev {
    left: -7px;

    @media (min-width: 576px) {
      left: -50px;
    }

    @media (min-width: 768px) {
      left: -80px;
    }

    &::before {
      background-image: url(../../_static/img/icons/streamline/arrows-diagrams/arrows/arrow-left-1.svg);
    }
  }

  .slick-next {
    right: -7px;

    @media (min-width: 576px) {
      right: -50px;
    }

    @media (min-width: 768px) {
      right: -80px;
    }

    &::before {
      background-image: url(../../_static/img/icons/streamline/arrows-diagrams/arrows/arrow-right-1.svg);
    }
  }

  .slick-dots {
    bottom: -38px;

    @media (min-width: 768px) {
      bottom: -50px;
    }
  }

  img {
    margin-top: -65px;
    margin-bottom: 35px;
  }
}


.any-slider-slick-creative-use {
  @extend .any-slider-slick;

  .vjs-poster {
    background-size: cover;
  }

  .slick-current {
    .any-slider-slick-center {
      margin: 0 -100px;
      position: relative;
      z-index: 1;

      .embed-responsive {
        border: none;
      }
    }
  }

  .slick-arrow {
    &::before {
      margin-top: 46%;

      @media (min-width: 991px) {
        margin-top: 25px;
      }
    }
  }
}


// MODULES: Text Image

.any-module-textimgout {
  .any-module-textimgout-img {
    width: 80%;
    margin: 0 auto;

    @media (min-width: 768px) {
      width: 100%;
      margin-left: -150px;
    }

    @media (min-width: 1200px) {
      margin-left: -400px;
    }
  }
}

.any-module-textimgout-left {
  .any-module-textimgout-img {
    width: 80%;
    margin: 0 auto;

    @media (min-width: 768px) {
      width: 100%;
      margin-right: -150px;
    }

    @media (min-width: 1200px) {
      margin-right: -400px;
    }
  }
}

.any-module-textblocksimg {
  .any-module-textblocksimg-img {
    width: 80%;
    margin-left: 0;
    margin-bottom: 30px;

    @media (min-width: 992px) {
      width: 200%;
      margin-left: 35px;
      margin-bottom: 0;
    }

    @media (min-width: 1200px) {
      width: 150%;
    }
  }

  .any-module-textblocksimg-img-sm {
    width: 80%;

    @media (min-width: 992px) {
      width: 200%;
      margin-left: 35px;
    }

    @media (min-width: 1200px) {
      width: 120%;
    }
  }

  .any-module-textblocksimg-img-fade {
    @media (min-width: 992px) {
      width: 230%;
      margin-left: -60px;
    }

    @media (min-width: 1200px) {
      width: 200%;
    }
  }
}

.any-module-textblocksimg-right {
  .any-module-textblocksimg-img {
    width: 80%;
    margin-left: 0;
    margin-bottom: 30px;

    @media (min-width: 992px) {
      width: 200%;
      margin-left: -235px;
      margin-bottom: 0;
    }

    @media (min-width: 1200px) {
      width: 150%;
    }
  }

  .any-module-textblocksimg-img-fade {
    @media (min-width: 992px) {
      width: 230%;
      margin-left: -135px;
    }

    @media (min-width: 1200px) {
      width: 200%;
    }
  }
}

.any-module-textimg {
  .any-module-textimg-img {
    opacity: 1;
  }

  .any-module-textvideo-img {
    cursor: pointer;
    opacity: 1;
    border: 1px solid $gray-medium;
  }

  .any-module-textvideo-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
    width: 80px;

    @media (min-width: 768px) {
      width: 120px;
    }
  }

  .embed-responsive {
    max-width: 667px;
    margin: 0 auto;
  }
}

.any-module-videoNav {
  .embed-responsive {
    max-width: 903px;
  }
}

.embed-image {
  margin-bottom: 1.8%;
}

.embed-responsive {
  border: 1px solid $gray-medium;
}

.any-module-iconfeatureimg {
  .any-module-iconfeatureimg-img {
    width: 80%;

    @media (min-width: 992px) {
      width: 200%;
      margin-left: 35px;
    }

    @media (min-width: 1200px) {
      width: 150%;
    }
  }
}

// MODULE: Triumph with Image

.any-module-triumphImg {
  img {
    width: 80%;
  }

  .any-module-triumph-content {
    text-align: center;
    margin-top: 15px;

    @media (min-width: 768px) {
      text-align: left;
      margin-top: -15px;
      margin-left: -100px;
    }

    .any-module-triumph-value1 {
      font-size: 3rem;
      color: $primary;

      @media (min-width: 768px) {
        font-size: 3.5rem;
      }

      @media (min-width: 1100px) {
        font-size: 4rem;
      }

      @media (min-width: 1300px) {
        font-size: 4.5rem;
      }
    }

    .any-module-triumph-value2 {
      font-size: 1.2rem;
      color: $primary;

      @media (min-width: 768px) {
        font-size: 1.5rem;
      }

      @media (min-width: 1100px) {
        font-size: 2rem;
      }

      @media (min-width: 1300px) {
        font-size: 2.5rem;
      }
    }

    .any-module-triumph-value3 {
      display: block;
      margin-top: -20px;
      margin-bottom: 20px;
    }
  }
}

.any-module-triumphImg-fluid {
  @extend .any-module-triumphImg;

  img {
    width: 100%;
  }

  .any-module-triumph-content {
    margin-left: 0;

    @media (min-width: 768px) {
      margin-left: -200px;
    }

    @media (min-width: 992px) {
      margin-left: -300px;
    }
  }
}


// MODULE: IMG Box

.any-module-imgBox {
  position: relative;
  border: 1px solid $gray-medium;
  text-align: left;

  .any-module-imgBox-content {
    padding: 25px;
  }

  .any-module-imgBox-link {
    position: absolute;
    left: 25px;
    bottom: 25px;
  }

  .any-module-imgBox-iconContainer {
    position: absolute;
    right: 25px;
    bottom: 0;
    padding: 20px 10px 10px 10px;
    background: rgb(0, 0, 0);
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 10%, rgba(0, 0, 0, 0.5018382352941176) 50%, rgba(0, 0, 0, 1) 90%, rgba(0, 0, 0, 1) 100%);
  }

  .any-module-imgBox-langContainer {
    position: absolute;
    right: 25px;
    bottom: 25px;
    color: $gray-light-60;

    a {
      color: $gray-light-60;
      border-bottom-color: $gray-light-60;
    }
  }
}


// MODULE: Filter

.any-module-filter {
  min-width: 250px;

  @media (min-width: 768px) {
    min-width: 415px;
  }
}

.any-module-filter-pastevents {
  width: 165px;
  min-width: auto;

  @media (min-width: 768px) {
    width: 165px;
    min-width: auto;
  }
}

.any-module-filter-pastevents-text {
  height: 58px;
}

.any-module-filter-upcomingevents {
  width: 100%;
  min-width: auto;

  @media (min-width: 768px) {
    width: 100%;
    min-width: auto;
  }
}


// MODULE: Newsletter

.any-module-newsletter, .any-module-downloadthankyou {
  .form-control {
    width: 100% !important;

    @media (min-width: 768px) {
      width: 350px !important;
    }
  }

  .form-inline {
    width: 100%;

    .input-group {
      width: 100%;

        .input-group-append {
          margin-top: 2px;
        }
    }
  }

  @media (min-width: 768px) {
    .form-inline {
      width: auto;

      .input-group {
        width: auto;
      }
    }
  }

  .btn-fix-border {
    &:hover {
      border-bottom: none;
    }
  }

  .custom-checkbox {
    font-weight: 500;

    [type="checkbox"] + label:after {
      box-shadow: 0px 0px 4px #707070;
    }
  }
}

.any-module-downloadthankyou {
  .form-control {
    @media (min-width: 768px) {
      width:550px !important;
    }

    @media (min-width: 992px) {
      width: 700px !important;
    }
  }
}


// MODULE: Hero Img Box

.any-module-hero-imgBox {
  position: relative;
  background-size: cover;
  background-position: center;
  color: white;
  height: auto;
  min-height: 300px;
  @extend .any-transition-fade;

  @media (min-width: 992px) {
    margin-right: 10px;
    min-height: 350px;
  }

  @media (min-width: 1024px) {
    min-height: 400px;
  }

  img {
    display: inline-block;
  }
}

// Hero Img Box with Link

.any-module-hero-imgBox-wrapperlink {
  color: white;
  font-weight: 100;
  overflow: hidden;
  display: block;
  width: 100%;
  height: 100%;
  border-bottom: none;

  &:hover {
    .any-module-hero-imgBox {
      @extend .any-transition-fade;
      transform: scale(1.05);
    }
  }

  .any-module-hero-imgBox {
    @media (min-width: 992px) {
      margin-right: 0;
    }
  }
}


// MODULE: Hover Images Company

.any-module-circleHoverImg {
  font-weight: 300;
  text-decoration: none;
  border-bottom: none;

  img {
    border: 2px solid $gray-light;
    border-radius: 50%;
  }

  p {
    color: $body-color;
    text-decoration: none;
  }

  &:hover {
    text-decoration: none;

    img {
      @extend .any-transition-fade;
      border: 2px solid $primary;
    }
  }
}

.any-module-squareHoverImg {
  position: relative;
  font-weight: 300;
  font-size: 14px;
  text-decoration: none;
  border-bottom: none;
  text-align: center;
  width: 180px;
  height: 180px;
  overflow: hidden;
  margin: 0 auto;

  .any-module-squareHoverImg-img {
    position: relative;
    height: 180px;
    width: 180px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: bottom;
    margin: 0 auto;
    @extend .any-transition-fade;
  }

  .any-module-squareHoverImg-img-hovertext {
    transition: all .3s ease-out;
    position: absolute;
    left: 50%;
    margin-left: -90px;
    top: 30%;
    width: 180px;
    height: 180px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
    background: $bg-color-black-transparent;
    visibility: hidden;
    opacity: 0;
    padding: 10px;
    cursor: default;
    border-bottom: 3px solid $primary;
    color: white;
    height: 180px;
    font-size: 10px;
  }

  &:hover {
    .any-module-squareHoverImg-img {
      @extend .any-transition-fade;
      transform: scale(1.1);
    }

    .any-module-squareHoverImg-img-hovertext {
      @extend .any-transition-fade;
      visibility: visible;
      opacity: 1;
      top: 0;
    }
  }
}


// MODULE: Date Image Boxes

.any-module-dateImgBox {
  border: 1px solid $gray-medium;

  .any-module-dateImgBox-img {
    width: 100%;
    height: 300px;
    background-size: cover;
    background-position: 50%;
  }

  .any-module-dateImgBox-date {
    @media (min-width: 992px) {
      float: left;
      width: 25%;
    }

    .any-module-dateImgBox-location {
      margin-bottom: 30px;
      font-weight: bold;

      @media (min-width: 992px) {
        margin-bottom: 0;
      }
    }
  }

  .any-module-dateImgBox-title {
    @media (min-width: 992px) {
      float: left;
      width: 55%;
    }
  }

  .any-module-dateImgBox-link {
    position: absolute;
    bottom: 0;
    right: 0;
    text-align: right;
    width: 100%;
  }
}

.any-module-dateImgBoxLarge {
  @extend .any-module-dateImgBox;
  height: 100%;

  .any-overlay-light {
    height: 300px;
    width: 100%;

    @media (min-width: 992px) {
      height: 410px;
      width: 50%;
    }
  }

  .any-module-dateImgBox-img {
    width: 100%;
    float: none;

    @media (min-width: 992px) {
      width: 50%;
      height: 406px;
      float: left;
    }
  }

  .any-module-dateImgBox-content {
    height: 100%;
    width: 100%;
    float: none;

    @media (min-width: 992px) {
      width: 50%;
      float: left;
    }
  }

  .any-module-dateImgBox-date {
    @media (min-width: 768px) {
      float: none;
      width: 100%;
    }
  }

  .any-module-dateImgBox-title {

    @media (min-width: 768px) {
      float: none;
      width: 100%;
    }
  }

  .any-module-dateImgBox-link {
    @media (min-width: 768px) {
      width: 100%;
    }
  }
}

.any-module-dateImgBoxSmall {
  @extend .any-module-dateImgBox;

  .any-module-dateImgBox-img {
    height: 250px;
  }

  .any-module-dateImgBox-content {
    border-top: 5px solid $primary;

    .any-module-dateImgBox-date {
      @media (min-width: 768px) {
        float: none;
        width: 100%;
      }
    }

    .any-module-dateImgBox-title {

      @media (min-width: 768px) {
        float: none;
        width: 100%;
      }
    }

    .any-module-dateImgBox-link {
      @media (min-width: 768px) {
        width: 100%;
      }
    }
  }
}

.any-module-dateImgBoxFlat {
  @extend .any-module-dateImgBox;
  overflow: hidden;
  height: 100%;

  .any-module-dateImgBox-img-wrapper {
    width: 100%;
    height: 295px;

    @media (min-width: 992px) {
      width: 25%;
      margin-right: 5%;
      margin-bottom: -33px;
      float: left;
    }

    @media (min-width: 1200px) {
      height: 270px;
    }

    .any-module-dateImgBox-img {
      height: 100%;
    }
  }

  .any-module-dateImgBox-content {
    height: 100%;
  }

  .any-module-dateImgBox-date {
    @media (min-width: 992px) {
      width: 20%;
    }
  }

  .any-module-dateImgBox-title {
    @media (min-width: 992px) {
      width: 35%;
    }
  }

  .any-module-dateImgBox-link {
    @media (min-width: 992px) {
      width: 15%;
    }
  }
}


// MODULE: Square Hover Jumbotrons

.any-module-squareHoverBox-selected {
  @extend .any-boxshadow;
  @extend .border-solid-gradient-2px;
}

.any-module-squareHoverBox {
  cursor: pointer;
  text-align: center;
  display: block;
  padding: 2rem 1rem;
  border: 2px solid $gray-light;
  background-color: white;

  p {
    font-weight: 100;
  }


  &:hover {
    @extend .any-module-squareHoverBox-selected;

    .any-link {
      border-bottom: none;
    }
  }
}

.any-module-squareHoverBox-formControl {
  width: 180px;
  margin: 0 auto;
}

.any-module-squareHoverBox-dropdown {
  width: 100px;
  margin: 0 auto;
}


// MODULE: Quote Area

.any-module-quote {
  .any-module-quote-icon {
    left: 75px;

    @media (min-width: 576px) {
      left: 150px;
    }

    @media (min-width: 768px) {
      left: 0;
    }

    @media (min-width: 992px) {
      left: 15px;
    }

    @media (min-width: 1200px) {
      left: 60px;
    }
  }

  .any-module-quote-img {
    width: 180px;
    height: 180px;
  }
}

.any-module-quote-purple {
  @extend .any-transition-fade;
  background-color: black;
  position: relative;
  padding: 30px 80px 30px 30px;

  @media (min-width: 577px) {
    padding: 30px 120px 30px 30px;
  }

  @media (min-width: 1200px) {
    padding: 30px 230px 30px 30px;
  }

  .any-module-quote-purple-border {
    position: absolute;
    left:0;
    top:0;
    height:100%;
    width: 5px;
    background: transparent linear-gradient(258deg, #BE70DF 0%, #1D49A7 100%) 0% 0% no-repeat padding-box;
  }

  .any-module-quote-purple-link-mobile {
    position: absolute;
    top: 20px;
    right: 20px;

    a {
      border-bottom: 0;
    }
  }

  .any-module-quote-purple-info {
    position: absolute;
    right: -5px;
    bottom: -32px;
    width: 100%;

    @media (min-width: 577px) {
      bottom: -35px;
    }

    .any-module-quote-purple-info-link {
      display: flex;
      align-items: end;
      float: left;
      height: 44px;
      margin-left: 10px;

      @media (min-width: 577px) {
        height: 110px;
        margin-left: 0;
      }

      span {
        color: $purple;
        border-bottom: 1px solid $purple;
      }
    }

    .any-module-quote-purple-info-author {
      display: flex;
      align-items: end;
      float: right;

      img {
        width: 85px;

        @media (min-width: 577px) {
          width: 110px;
        }
      }

      .h5 {
        background-color: rgba(255,255,255,.5);
        padding: 2px 15px;

        @media (min-width: 769px) {
          padding: 0 15px;
        }
      }
    }
  }
}


// MODULE: Form Pill with Icon Logo button

.any-module-btn-logo {
  .btn {
    &:hover {
      border-bottom: none;
    }
  }
}


// MODULE: Home Image + Animation

.home-animation-text {
  color: $primary;
  font-weight: bold;
}

.home-image-laptops-glow {
  width: 100%;
  margin-top: 0;
  margin-left: 0;
  position: absolute;
  top: -89px;
  right: -160px;
  opacity: .5;
  z-index: 2;

  @media (min-width: 576px) {
    right: -200px;
  }

  @media (min-width: 768px) {
    width: 200%;
    margin-top: -50px;
    margin-left: -115px;
    position: relative;
    top: auto;
    right: auto;
    opacity: 1;
  }

  @media (min-width: 992px) {
    width: 140%;
    margin-top: -100px;
    margin-left: -50px;
  }

  @media (min-width: 1200px) {
    width: 100%;
  }
}

.home-image-laptops {
  width: 230px;
  margin-top: 0;
  margin-left: 0;
  position: absolute;
  top: 0;
  right: -40px;
  opacity: .5;
  z-index: 2;

  @media (min-width: 576px) {
    right: -40px;
  }

  @media (min-width: 768px) {
    width: 200px;
    margin-top: 0;
    margin-left: 0;
    position: relative;
    top: auto;
    right: auto;
    opacity: 1;
  }

  @media (min-width: 992px) {
    width: 240px;
    margin-left: 80px;
    margin-top: 0;
  }

  @media (min-width: 1200px) {
    width: 300px;
    margin-left: 140px;
    margin-top: 0;
  }
}


// MODULE: Hero Video

.any-module-hero-video {
  height: auto;
  background-color: #2C0D03;

  .any-module-hero-video-content {
    height: auto;
    color: white;
  }

  .any-overlay {
    background: linear-gradient(90deg, rgba(0,0,0,1) 0%, rgba(0,0,0,1) 15%, rgba(0,0,0,1) 20%, rgba(0,0,0,0) 100%);
  }

  video {
    position: absolute;
    bottom: 0;
    right: -20%;
    min-width: 100%;
    width: inherit;
    height: 122%;
    z-index: 0;
    pointer-events: none;
    object-fit: cover;
  }
}

.any-video-dimensions {
  min-width: 100%;
  width: inherit;
  height: 122%;
}


// MODULE: Sticky Bar

.any-module-stickyBar {
  position: sticky;
  position: -webkit-sticky;
  z-index: 1;
  top: 0;
  background: $white;

  @media (min-width: 992px) {
    transition: top 0.2s ease-in-out;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
  }
}

.sticky-up {
  @media (min-width: 992px) {
    top: 0;
  }
}

.sticky-down {
  @media (min-width: 992px) {
    top: 126px;
  }
}


// MODULE: Video Overlay

.any-module-videoOverlay-wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  bottom: 30px;
  z-index: 1;
}

.any-module-videoOverlay {
  @extend .any-overlay;
  z-index: 2147483647;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  backface-visibility: hidden;
  color: white;
  padding-top: 30px;
}


// MODULE: Headline Margin Button
.any-header-mb-xs {
  margin-bottom: $header-mb;

  @media (min-width: 768px) {
    margin-bottom: $header-mb / 2;
  }
}

.any-header-mb-sm {
  margin-bottom: $header-mb * 2;

  @media (min-width: 768px) {
    margin-bottom: $header-mb;
  }
}

.any-header-mb-md {
  margin-bottom: $header-mb * 2.5;

  @media (min-width: 768px) {
    margin-bottom: $header-mb * 1.5;
  }
}

.any-header-mb-lg {
  margin-bottom: $header-mb * 3.5;

  @media (min-width: 768px) {
    margin-bottom: $header-mb * 2.5;
  }
}

.any-header-mb-xl {
  margin-bottom: $header-mb * 4.5;

  @media (min-width: 768px) {
    margin-bottom: $header-mb * 3.7;
  }
}

// MODULE: Video Overlay

.any-module-videoOverlay-wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  bottom: 30px;
  z-index: 1;
}

.any-module-videoOverlay {
  @extend .any-overlay;
  z-index: 2147483647;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  backface-visibility: hidden;
  color: white;
  padding-top: 30px;
}


// MODULE: Hash Badge

.any-module-hashBadge {
  position: absolute;
  background-color: white;
  left: 50%;
  padding: 10px 20px;
  margin-top: -60px;
  margin-left: -94px;

  @media (min-width: 576px) {
    margin-left: -106px;
  }

  @media (min-width: 768px) {
    margin-left: -120px;
  }

  @media (min-width: 992px) {
    left: 100px;
    padding: 20px 40px;
    margin-top: -90px;
    margin-left: 0;
  }

  @media (min-width: 1200px) {
    left: 25%;
  }

  @media (min-width: 3000px) {
    left: 35%;
  }
}


// MODULE: Text Img Quote

.any-module-textImgQuote {
  .any-module-textImgQuote-box {
    margin-right: 0;
    padding: 30px 30px 70px 30px;

    @media (min-width: 576px) {
      padding: 50px 50px 90px 50px;
    }

    @media (min-width: 992px) {
      margin-right: -150px;
      padding: 50px 90px 50px 50px;
    }
  }

  .any-module-textImgQuote-name {
    position: absolute;
    right: auto;
    left: 180px;
    width: auto;
    margin-right: 0;
    margin-top: 0;

    @media (min-width: 576px) {
      left: 230px;
    }

    @media (min-width: 992px) {
      right: 0;
      left: auto;
      width: 270px;
      margin-right: -400px;
      margin-top: -120px;
    }
  }

  .any-module-textImgQuote-name-long {
    @extend .any-module-textImgQuote-name;
    margin-top: 100px;
    left: 0;

    @media (min-width: 400px) {
      margin-top: 0;
      left: 180px;
    }

    @media (min-width: 576px) {
      left: 230px;
    }

    @media (min-width: 992px) {
      width: 348px;
      margin-right: -480px;
      margin-top: -120px;
      left: auto;
    }

    @media (min-width: 1350px) {
      width: 510px;
      margin-right: -640px;
    }
  }

  .any-module-quote {
    margin-right: 0;
    margin-top: -70px;
    float: left;
    margin-left: -35px;

    @media (min-width: 992px) {
      margin-right: -240px;
      margin-top: -70px;
      margin-left: 0;
      float: right;
    }

    .any-module-quote-icon {
      margin-top: -100px;
      left: 60px;

      @media (min-width: 992px) {
        margin-top: -150px;
      }
    }

    .any-module-quote-img {
      margin-top: 20px;
      width: 150px;
      height: 150px;

      @media (min-width: 576px) {
        width: 180px;
        height: 180px;
      }

      @media (min-width: 992px) {
        margin-top: 0;
      }
    }
  }
}

// MODULE: Headline Margin Button
.any-header-mb-xs {
  margin-bottom: $header-mb;

  @media (min-width: 768px) {
    margin-bottom: $header-mb / 2;
  }
}

.any-header-mb-sm {
  margin-bottom: $header-mb * 2;

  @media (min-width: 768px) {
    margin-bottom: $header-mb;
  }
}

.any-header-mb-md {
  margin-bottom: $header-mb * 2.5;

  @media (min-width: 768px) {
    margin-bottom: $header-mb * 1.5;
  }
}

.any-header-mb-lg {
  margin-bottom: $header-mb * 3.5;

  @media (min-width: 768px) {
    margin-bottom: $header-mb * 2.5;
  }
}

.any-header-mb-xl {
  margin-bottom: $header-mb * 4.5;

  @media (min-width: 768px) {
    margin-bottom: $header-mb * 3.7;
  }
}

// MODULE: Play Button
.vjs-big-play-button {
  height: 85px !important;
  width: 85px !important;
  background-color: rgba(255, 255, 255, .50) !important;
  border-radius: 45px !important;
  border: none !important;
  top: 48% !important;

  .vjs-icon-placeholder {
    &:before {
      content: url("/_static/img/icons/streamline/video-movies-tv/video-players/controls-play-white.svg") !important;
      width: 50% !important;
      height: 50% !important;
      top: 22px !important;
      left: 25px !important;
    }
  }
}

// MODULE: Before After IMG Slider
.any-before-after-slider-container {
  position: relative;
  width: 0;
  height: 0;
  margin-right: auto;
  margin-left: auto;

  @media (min-width: 786px) {
    height: 600px;
    width: 745px;
  }

  @media (min-width: 992px) {
    width: 900px;
  }

  @media (min-width: 1200px) {
    width: 797px;
  }

  @media (min-width: 1400px) {
    width: 900px;
  }

  .any-before-after-slider-imgPos {
    position: absolute;
    top: 0;
    left: -2px;
    width: 100%;
    height: 100%;
  }

  .any-layer-first-retail {
    background-image: url("/_static/img/resources/woman-shopping-list-before.jpg");
  }

  .any-layer-second-retail {
    background-image: url("/_static/img/resources/woman-shopping-list-after.jpg");
    width: 49.5%;
  }

  .any-layer-first-education {
    background-image: url("/_static/img/resources/education-before.jpg");
  }

  .any-layer-second-education {
    background-image: url("/_static/img/resources/education-after.jpg");
    width: 49.5%;
  }

  .any-layer-first-influencer,
  .any-layer-first-media-creatives {
    background-image: url("/_static/img/resources/influencer-before.jpg");
  }

  .any-layer-second-influencer,
  .any-layer-second-media-creatives {
    background-image: url("/_static/img/resources/influencer-after.jpg");
    width: 49.5%;
  }

  .any-layer-first-manufacturing,
  .any-layer-first-engineering-and-design,
  .any-layer-first-energy-and-industry {
    background-image: url("/_static/img/resources/woman-man-ipad-factory-before.jpg");
  }

  .any-layer-second-manufacturing,
  .any-layer-second-engineering-and-design,
  .any-layer-second-energy-and-industry {
    background-image: url("/_static/img/resources/woman-man-ipad-factory-after.jpg");
    width: 49.5%;
  }
}

.any-beforeAfterSlider {
  position: absolute;
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  height: 100%;
  background: rgba(#f8f8f8, .0);
  transition: all .2s;
  display: flex;
  justify-content: center;
  align-items: center;

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 2px;
    height: 400px;
    background: white;
    cursor: pointer;
  }

  &::-moz-range-thumb {
    width: 2px;
    height: 400px;
    background: white;
    cursor: pointer;
  }
}

.any-drag-icon {
  pointer-events: none;
  position: absolute;
  width: 35px;
  height: 35px;
  bottom: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: 2px solid $red;
  background: $white;
  left: 47.6%;

  &:after {
    content: '';
    padding: 3px;
    display: inline-block;
    border: solid $red;
    border-width: 0 2px 2px 0;
    transform: rotate(-45deg);
  }

  &:before {
    content: '';
    padding: 3px;
    display: inline-block;
    border: solid $red;
    border-width: 0 2px 2px 0;
    transform: rotate(135deg);
  }
}


// MODULE: Fixed Banner

.any-bannerBottomFixed {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: $primary;
  color: white;
  z-index: 100;
  padding: 10px;

  button {
    opacity: 1;
  }

  .close {
    span {
      background-image: url(../../_static/img/icons/streamline/interface-essential/form-validation/close-white.svg);
      background-repeat: no-repeat;
      width: 20px;
      height: 20px;
      position: absolute;
      right: 15px;
      top: 22px;
    }
  }
}

.any-bannerBottomFixedOnlyImg {
  background-color: transparent;
  padding: 0;

  a {
    border: none;
  }

  .close {
    span {
      background-image: url(../../_static/img/icons/streamline/interface-essential/form-validation/close.svg);
      background-color: rgba(255,255,255,0.5);
      border-radius: 25px;
      left: 3%;
      top: -10%;

      @media (min-width: 576px) {
        left: auto;
        right: 3%;
      }

      @media (min-width: 768px) {
        right: 14%;
        top: 25%;
      }

      @media (min-width: 1280px) {
        right: 26%;
      }
    }
  }
}

.any-floatBoxFixedRight {
  display: none;
  background-color: white;

  @media (min-width: 1700px) {
    display: block;
    position: absolute;
    right: 0;
    top: 640px;
    height: 300px;
    width: 300px;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
  }

  .any-floatBoxFixedRight-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 100%;
  }
}

.any-floatBoxFixedLeft {
  display: none;

  @media (min-width: 1700px) {
    display: block;
    position: absolute;
    left: 0;
    top: 640px;
    height: 300px;
    width: 300px;
    background: $primary-orange-gradient-bg;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    border-left: 0;
    color: $white;
    transition: all .5s ease-out;
    overflow: hidden;

    .any-floatBoxFixedLeft-content {
      .monitor-license {
        display: none;
        position: absolute;
        top: 50px;
        right: -225px;
        width: 300px;
      }
    }

    &:hover {
      background: $primary-orange-gradient-bg;
      width: 450px;

      .any-floatBoxFixedLeft-content {
        width: 300px;
        height: 300px;
        color: $white;
        padding-right: 3rem !important;
        padding-left: 1rem !important;
        position: relative;

        .icon-click {
          display: none;
        }

        .monitor-license {
          display: block;
        }
      }
    }

    .any-floatBoxFixedLeft-content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      height: 300px;
      width: 300px;

      .icon-click {
        position: absolute;
        bottom: -4px;
        right: -6px;
      }
    }
  }
}

.any-header-link-noBubble {
  display: inline;

  @media (min-width: 1700px) {
    display: none;
  }
}

.any-featuresList {
  line-height: normal;

  .any-featuresList-item {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;

  }

  .any-featuresList-sticky-title {
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    text-align: center;
  }

  .any-featuresList-item-title {
    text-align: center;

    @media (min-width: 992px) {
      text-align: right;
    }
  }
}

.any-module-pricingTable-labelValue {
  position: absolute;
  top: -37px;
  width: 100%;
  letter-spacing: 1px;
  text-align: center;
  font-size: 21px;
  color: $gray-font;
}

.any-featuresListMobile {
  margin-top: 20px;

  .table {
    font-size: .8rem !important;

    thead {
      tr {
        background: white;
        text-align: center;
        font-weight: bold !important;
        padding: 20px;

        th {
          position: -webkit-sticky;
          position: sticky;
          top: 0;
          z-index: 2;
          background: $white;
        }
      }
    }

    tbody {
      th {
        vertical-align: middle;
        text-align: left !important;
        font-weight: normal;
        padding: 0.5rem;
        position: -webkit-sticky;
        position: sticky;
        left: 0;
        z-index: 1;
        background-color: $gray-light;
      }

      td {
        vertical-align: middle;
        text-align: center;
        font-weight: normal;
        padding: 0.5rem;
        border: 1px solid $gray-medium;
      }
    }
  }

  .table-striped tbody tr:nth-of-type(odd) {
    background-color: $gray-light;
  }
}

.any-iconWall-sevenRows {
  display: inline-block;
  width: 23%;
  border-radius: 6px;
  overflow: hidden;

  @media (min-width: 768px) {
    width: 13.5%;
  }

  @media (min-width: 1300px) {
    width: 14%;
  }

  img {
    @media (max-width: 399px) {
      width: 35px;
      margin-top: 0.5rem !important;
    }
  }

  p {
    @media (max-width: 399px) {
      font-size: 0.8rem;
      margin-bottom: 0.5rem !important;
    }
  }
}

.any-iconWall-eightRows {
  display: inline-block;
  width: 49%;
  border-radius: 6px;
  overflow: hidden;

  @media (min-width: 576px) {
    width: 24%;
  }

  @media (min-width: 1200px) {
    width: 12%;
  }
}


// MODULE: SideBox

.any-module-sideBox {
  background: $primary-orange-gradient-bg;
  width: 100%;
  padding: 40px;
  color: white;
  margin-top: -207px;
  overflow: hidden;
  position: relative;
  height: 132%;

  h3, p {
    width: 100%;

    @media (min-width: 1200px) {
      width: 60%;
    }
  }

  h3{
    @media (max-width: 1200px) {
      font-size: 2.5rem;
    }
  }

  .laptop-anydesk-arrow {
    position: absolute;
    left: -70px;
    top: 0;
    width: 555px;

    @media (min-width: 1600px) {
      left: 0;
    }
  }

  .call-sentence {
    border-bottom: none;
    color: $white !important;
    z-index: 1;
    position: relative;
    margin-top: 450px;
    display: block;

    @media (min-width: 1724px) {
      margin-top: 475px;
    }
  }
}

.any-module-shadowHoverBox {
  text-align: center;
  padding: 35px;
  display: block;
  border: none;
  color: $body-color;

  &:hover {
    @extend .any-boxshadow-left-right;
    color: $primary;
    background: $gray-lighter;
  }
}


// MODULE: Company Story

.any-module-companyStory {
  h3 {
    position: relative;
    z-index: 1;
    margin-bottom: -20px;

    @media (min-width: 768px) {
      margin-bottom: -50px;
    }

    @media (min-width: 992px) {
      margin-bottom: -70px;
    }

    @media (min-width: 1200px) {
      margin-bottom: -95px;
    }
  }

  .any-module-companyStory-textblock-wrapper {
    margin-top: 30px;

    @media (min-width: 768px) {
      margin-top: -40px;
    }

    @media (min-width: 992px) {
      margin-top: -60px;
    }

    @media (min-width: 1200px) {
      margin-top: -70px;
    }

    .any-module-companyStory-textblock {
      display: inline-block;
      width: 100%;
      vertical-align: top;
      padding: 0 15px;

      @media (min-width: 768px) {
        width: 22%;
      }
    }
  }

}

.any-imagesWall-fiveRow {
  display: inline-block;
  width: 33%;

  @media (min-width: 576px) {
    width: 25%;
  }

  @media (min-width: 768px) {
    width: 15%;
  }

  @media (min-width: 1024px) {
    width: 12%;
  }
}

// MODULE: Scrollable List

.any-list-scrollable {
  .any-list-scrollable-inner {
    position: relative;
    height: 230px;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      display: none;
    }

    ul {
      margin-bottom: 80px;

      li {
        margin-bottom: 7px;
         &:hover {
           font-weight: 700;
           cursor: pointer;
         }
      }
    }
  }

  ul {
    margin-bottom: 80px;

    li {
      margin-bottom: 7px;
      &:hover {
        font-weight: 700;
        cursor: pointer;
      }
    }
  }

  .any-list-scrollable-gradient {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 80px;
    background: linear-gradient(to top, #f8f8f8, transparent);
    pointer-events: none;
  }
}


// MODULE: Collapse Link

.any-module-collapse-link {
  cursor: pointer;
  overflow: hidden;

  .any-module-quote-purple {
    background: linear-gradient(90deg, rgba(0,0,0,1) 0%, rgba(0,0,0,1) 50%, rgba(0,0,0,1) 100%);
  }

  .container-hero {
    transition: all 0.3s ease-in-out;
  }

  &:hover {
    .any-module-quote-purple {
      background: linear-gradient(90deg, rgba(50,59,106,1) 0%, rgba(0,0,0,1) 50%, rgba(0,0,0,1) 100%);
      transform: scale(0.97);
    }

    .container-hero {
      transform: scale(1.03);
    }
  }
}


// MODULE: ProBadge

.any-module-proBadge {
  background-color: $primary;
  border-radius: 25px;
  padding: 0 10px;
  color: white;
  font-weight: bold;
  font-size: 14px;
  margin-left: 5px;
}


// MODULE: License Configurator

.any-module-license-configurator {
  background-position: bottom;

  @media (min-width: 1725px) {
    background-position: top;
  }

  @media (min-width: 3000px) {
    background-size: contain;
  }

  .any-module-license-configurator-text {
    margin-top: 10px;
    margin-bottom: 30px;

    @media (min-width: 576px) {
      margin-top: 190px;
      margin-bottom: 60px;
    }

    @media (min-width: 2190px) {
      margin-top: 260px;
    }

    @media (min-width: 3000px) {
      margin-top: 190px;
    }
  }
}

.any-module-cyberWeek-badge {
  margin-top: -65px;
  margin-bottom: -45px;

  @media (min-width: 768px) {
    margin-top: -84px;
    margin-bottom: -66px;
  }

  @media (min-width: 992px) {
    margin-top: 0;
    margin-bottom: 0;

    position: absolute;
    top: -35px;
    right: -15px;
    max-width: 500px;
  }

  @media (min-width: 1200px) {
    top: -50px;
    max-width: 650px;
  }

  @media (min-width: 1400px) {
    top: -100px;
    right: -50px;
    max-width: 750px;
  }
}

// NEW NEW DESIGN MODULES

.any-module-contentBox {
  padding: 35px 25px;
  border: 1px solid black;
  border-radius: 6px;
  box-shadow: inset 0 0 135px -110px white;
  //border-image: conic-gradient(from var(--angle), $primary 50%, $orange 70%, $orange 92%, $primary 100%) 1;
  //animation: spin 3s linear infinite;
  /*mask-image: linear-gradient(red, red),
  linear-gradient(red, red);
  mask-clip: content-box, border-box;
  mask-composite: exclude;*/
  color: $body-color;

  @media (min-width: 768px) {
    padding: 35px 100px;
  }

  &::before {
    position: absolute;
    width: 100%;
    height: 100%;
    top:0;
    left: 0;
    background: rgba(255,255,255, 0.1);
  }
}

.any-module-contentBox-gradientBorder {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 0;
  border-image: conic-gradient(from var(--angle), $primary 50%, $orange 70%, $orange 92%, $primary 100%) 1;
  animation: spin 3s linear infinite;
  mask-image: linear-gradient(red, red),
  linear-gradient(red, red);
  mask-clip: content-box, border-box;
  mask-composite: exclude;
}

.any-module-contentBox-redBorder {
  border: 1px solid $primary;
}

@property --angle {
  syntax: "<angle>";
  inherits: true;
  initial-value: 0turn;
}

@keyframes spin {
  to {
    --angle: 1turn;
  }
}
