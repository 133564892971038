////// AnyDesk Grid

//// Containers & Headers

// Containers on Header

.container-mt-xs {
  z-index: 1;
  margin-top: $container-mt;

  @media (min-width: 768px) {
    margin-top: $container-mt / 2;
  }
}

.container-mt-sm {
  z-index: 1;
  margin-top: $container-mt * 2;

  @media (min-width: 768px) {
    margin-top: $container-mt * 1.5;
  }
}

.container-mt-md {
  z-index: 1;
  margin-top: $container-mt * 2.5;

  @media (min-width: 768px) {
    margin-top: $container-mt * 2;
  }
}

.container-mt-lg {
  z-index: 1;
  margin-top: $container-mt * 3.5;

  @media (min-width: 768px) {
    margin-top: $container-mt * 3;
  }
}

.container-mt-xl {
  z-index: 1;
  margin-top: $container-mt * 4.5;

  @media (min-width: 768px) {
    margin-top: $container-mt * 4;
  }
}

.container-gray {
  background-color: $gray-light;
}

.container-gray-lighter {
  background-color: $gray-lighter;
}

.container-white {
  background-color: white;
}

.container-dark-gray {
  background-color: $gray-dark;
}

.container-darker-gray {
  background-color: $gray-darker;
}

.container-medium-gray {
  background-color: #9E9E9E;
}

.container-gradient {
  background: $primary-orange-gradient-bg;
}

.container-fluid-md {
  @media (max-width: 991px) {
    max-width: 100%;
  }
}

.container-blackfriday-banner,
.container-cybermonday-banner {
  margin-top: 0;
  padding-bottom: 60px;

  @media (min-width: 992px) {
    margin-top: 123px;
  }
}


// Cols with margins

.col-mb-minus11rem {
  margin-bottom: -11rem;
}

.col-mt-7rem {
  margin-top: 7rem;
}


// Header

.container-header {
  position: relative;
  z-index: 1;

  padding-top: $container-padding-y * 2.6;
  padding-bottom: $container-padding-y / 2.75;
  margin-top: -168px;

  @media (min-width: 576px) {
    padding-top: $container-padding-y * 2;
    padding-bottom: $container-padding-y / 2.25;
    margin-top: -120px;
  }

  @media (min-width: 768px) {
    padding-bottom: $container-padding-y / 1.25;
    margin-top: -106px;
  }

  @media (min-width: 992px) {
    padding-top: $container-padding-y * 2.2;
    padding-bottom: $container-padding-y;
    margin-top: 0;
  }
}

.container-header-topBanner {
  @media (min-width: 992px) {
    padding-top: $container-padding-y * 2.7;
  }
}

.container-hero {
  position: relative;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  color: white;

  .any-link-border {
    color: white;

    &:hover {
      color: white;
    }
  }
}

.employees-sitting-office-jpg-hero-img {
  background-position: top;
}

.anydesk-logo-office-workers-jpg-hero-img {
  background-position: top;
}

.what-is-new-jpg-hero-img {
  color: $body-color;

  @media (max-width: 1199px) {
    background-image: none !important;
  }

  .any-module-hero-imgHidden {
    display: block;

    @media (min-width: 1200px) {
      display: none;
    }
  }
}

.what-is-new-salesforce-jpg-hero-img,
.what-is-new-v8-jpg-hero-img,
.what-is-new-anydesk-png-hero-img,
.switch-banner-extra-months-en-jpg-hero-img,
.switch-banner-extra-months-de-jpg-hero-img,
.switch-banner-extra-months-en-au-jpg-hero-img,
.switch-banner-extra-months-en-gb-jpg-hero-img,
.switch-banner-extra-months-el-jpg-hero-img,
.switch-banner-extra-months-es-jpg-hero-img,
.switch-banner-extra-months-fr-jpg-hero-img,
.switch-banner-extra-months-it-jpg-hero-img,
.switch-banner-extra-months-ja-jpg-hero-img,
.switch-banner-extra-months-ko-jpg-hero-img,
.switch-banner-extra-months-nl-jpg-hero-img,
.switch-banner-extra-months-pl-jpg-hero-img,
.switch-banner-extra-months-pt-jpg-hero-img,
.switch-banner-extra-months-ru-jpg-hero-img,
.switch-banner-extra-months-tr-jpg-hero-img,
.switch-banner-extra-months-zhs-jpg-hero-img,
.switch-banner-extra-months-zht-jpg-hero-img {
  color: $body-color;

  @media (max-width: 1199px) {
    background-image: none !important;
  }

  .any-module-hero-imgHidden {
    display: block;

    @media (min-width: 1200px) {
      display: none;
    }
  }
}

.man-sitting-outside-laptop-arrow-jpg-hero-img{
  color: $body-color;

  @media (max-width: 1199px) {
    background-image: none !important;
  }

  .any-module-textvideo-img {
    display: block;

    @media (min-width: 1200px) {
      display: none;
    }
  }
}

.anydesk-arrow-bg-png-hero-img {
  .any-overlay {
    background: none !important;
  }
}

.what-is-new-v9-png-hero-img {
  color: $body-color;

  @media (max-width: 1199px) {
    background-image: none !important;
  }

  @media (min-width: 2300px) {
    background-size: contain;
  }

  .any-module-hero-imgHidden {
    display: block;

    @media (min-width: 1200px) {
      display: none;
    }
  }
}

.license-configurator-monitor-png-hero-img {
  @media (max-width: 575px) {
    background-image: url(../../_static/img/hero/anydesk-arrow-red-orange-gradient-bg.png) !important;
  }
}

.newsletter-bg-img-png-hero-img {
  @media (min-width: 2000px) {
    background-size: contain;
    background-position: center !important;
  }
}

.anydesk-arrow-dark-png-hero-img {
  @media (max-width: 767px) {
    margin-top: -150px;
    padding-top: 11rem;
  }
}

.purple-arrow-bg-jpg-hero-img {
  @media (max-width: 991px) {
    margin-top: -162px;
    padding-top: 12rem;
  }
}



// Divider

.divider {
  height: 40px;
  width: 100%;

  @media (min-width: 576px) {
    height: 60px;
  }

  @media (min-width: 768px) {
    height: 80px;
  }

  @media (min-width: 992px) {
    height: 100px;
  }
}

.divider-sm {
  height: 20px;
  width: 100%;

  @media (min-width: 576px) {
    height: 30px;
  }

  @media (min-width: 768px) {
    height: 40px;
  }

  @media (min-width: 992px) {
    height: 50px;
  }
}

.divider-lg {
  height: 120px;
  width: 100%;

  @media (min-width: 576px) {
    height: 130px;
  }

  @media (min-width: 768px) {
    height: 140px;
  }

  @media (min-width: 992px) {
    height: 150px;
  }
}

.divider-special-template-headers {
  height: 170px;
  width: 100%;

  @media (min-width: 576px) {
    height: 145px;
  }

  @media (min-width: 768px) {
    height: 120px;
  }

  @media (min-width: 992px) {
    height: 0;
  }
}


// Footer

footer {
  background-color: $gray-darker;

  p {
    line-height: 1.2rem;
    margin-bottom: 12px;
  }

  a {
    font-weight: 300 !important;
    border-bottom: 0;
  }
}

.container-footer {
  a {
    color: white;
    text-decoration: none;

    &:hover {
      color: $primary;
      text-decoration: none;
      opacity: 1;
    }
  }

  .footer-logo {
    max-width: 230px;

    @media (min-width: 992px) {
      max-width: 270px;
    }
  }

  .language-select {
    .dropdown-menu {
      left: 0 !important;
    }
  }
}

.container-footer-bottom {
  background-color: #000000;
  color: white;
  padding: 2rem 0;

  a {
    &:hover {
      opacity: 1;
    }
  }

  .footer-social-icons {
    @extend .any-transition-fade;
    background-repeat: no-repeat;
    width: 25px;
    height: 25px;
    display: block;
    float: right;

    &:hover {
      @extend .any-transition-fade;
    }
  }

  .footer-social-icon-fb {
    @extend .footer-social-icons;
    background-image: url(../../_static/img/icons/new-design/facebook-logo.svg);

    &:hover {
      opacity: .75;
    }
  }

  .footer-social-icon-tw {
    @extend .footer-social-icons;
    background-image: url(../../_static/img/icons/new-design/twitter-logo.svg);

    &:hover {
      opacity: .75;
    }
  }

  .footer-social-icon-yt {
    @extend .footer-social-icons;
    background-image: url(../../_static/img/icons/new-design/youtube-logo.svg);

    &:hover {
      opacity: .75;
    }
  }

  .footer-social-icon-li {
    @extend .footer-social-icons;
    background-image: url(../../_static/img/icons/new-design/linkedin-logo.svg);

    &:hover {
      opacity: .75;
    }
  }

  .footer-social-icon-ig {
    @extend .footer-social-icons;
    background-image: url(../../_static/img/icons/new-design/instagram-logo.svg);

    &:hover {
      opacity: .75;
    }
  }

  .footer-social-icon-rd {
    @extend .footer-social-icons;
    background-image: url(../../_static/img/icons/new-design/reddit-logo.svg);

    &:hover {
      opacity: .75;
    }
  }

  .footer-social-icon-tt {
    @extend .footer-social-icons;
    background-image: url(../../_static/img/icons/new-design/tiktok-logo.svg);

    &:hover {
      opacity: .75;
    }
  }

  .footer-qr {
    width: 150px;
  }
}

.form-qr {
  width: 150px;
}

.container-footer-bottom-light {
  background-color: white;
  color: $body-color;

  .footer-social-icon-fb {
    @extend .footer-social-icons;
    background-image: url(../../_static/img/icons/streamline/logos/social-medias/social-media-facebook-red.svg);

    &:hover {
      background-image: url(../../_static/img/icons/streamline/logos/social-medias/social-media-facebook.svg);
    }
  }

  .footer-social-icon-tw {
    @extend .footer-social-icons;
    background-image: url(../../_static/img/icons/streamline/logos/social-medias/social-media-twitter-red.svg);

    &:hover {
      background-image: url(../../_static/img/icons/streamline/logos/social-medias/social-media-twitter.svg);
    }
  }

  .footer-social-icon-yt {
    @extend .footer-social-icons;
    background-image: url(../../_static/img/icons/streamline/logos/videos/social-video-youtube-clip-red.svg);

    &:hover {
      background-image: url(../../_static/img/icons/streamline/logos/videos/social-video-youtube-clip.svg);
    }
  }

  .footer-social-icon-li {
    @extend .footer-social-icons;
    background-image: url(../../_static/img/icons/streamline/logos/professional-networks/professional-network-linkedin-red.svg);

    &:hover {
      background-image: url(../../_static/img/icons/streamline/logos/professional-networks/professional-network-linkedin.svg);
    }
  }

  .footer-social-icon-ig {
    @extend .footer-social-icons;
    background-image: url(../../_static/img/icons/streamline/logos/photos-images/social-instagram-red.svg);

    &:hover {
      background-image: url(../../_static/img/icons/streamline/logos/photos-images/social-instagram.svg);
    }
  }

  .footer-social-icon-rd {
    @extend .footer-social-icons;
    background-image: url(../../_static/img/icons/streamline/logos/social-medias/social-media-reddit-red.svg);

    &:hover {
      background-image: url(../../_static/img/icons/streamline/logos/social-medias/social-media-reddit.svg);
    }
  }
}
