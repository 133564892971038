////// AnyDesk Variables Before

//// Only define variables if one of the following 2 things are given:
//// 1. You want to create totally new variables WITHOUT values which contain variables from "_variables.scss" or "_variables_custom_after.scss"
//// 2. You want to override variables from Bootstrap WITHOUT values which contain variables from "_variables.scss" or "_variables_custom_before.scss"

//// Color system

$red:       #ef443b !default;
$blue:      #2B7099 !default;
$green:     #01A273 !default;
$purple:    #9867D2 !default;
$orange:    #EE8D3B !default;

$red-light-10:  lighten($red, 90%);
$red-light-20:  lighten($red, 80%);
$red-light-30:  lighten($red, 70%);
$red-light-40:  lighten($red, 60%);
$red-light-50:  lighten($red, 50%);
$red-light-60:  lighten($red, 40%);
$red-light-70:  lighten($red, 30%);
$red-light-80:  lighten($red, 20%);
$red-light-90:  lighten($red, 10%);

$gray-lighter:      #F7F7F7; // created by AnyDesk
$gray-light:        #f8f8f8; // created by AnyDesk - as primary bg color
$gray-medium:       #dedede; // created by AnyDesk - as border color & secondary bg color
$gray:              #707070; // created by AnyDesk - as primary gray color
$gray-darklight:    #262626; // created by AnyDesk - as primary background color (dark-theme & footer)
$gray-dark:         #1A1A1A; // created by AnyDesk - as primary background color (dark-theme & footer)
$gray-darker:       #101010; // created by AnyDesk - as secondary background color for dark theme
$gray-font:         #000000; // created by AnyDesk - as standard font color & border color for dark theme
$red-dark:          #992327; // created by AnyDesk
$red-medium:        #F16058; // created by AnyDesk
$red-light:         #FF8E91; // created by AnyDesk
$red-mega-light:    #FFD5D6; // created by AnyDesk
$green-light:       #CCECE3; // created by AnyDesk
$blue-dark:         #377CC0; // created by AnyDesk
$purple-light:      #CB9BE2; // created by AnyDesk
$purple-dark:       #1D49A7; // created by AnyDesk
$purple-medium:     #BB70DE; // created by AnyDesk
$purple-mega-light: #F4E6FA; // created by AnyDesk


$gray-light-60:  lighten($gray-font, 40%); // created by AnyDesk - as placeholder text in forms
$gray-light-50:  lighten($gray-font, 50%); // created by AnyDesk - as placeholder text in forms

$gray-light-breadcrumbs:        #f1f1f1; // created by AnyDesk - as light gray color for breadcrumbs
$gray-lighter-breadcrumbs:      #C9C9C9; // created by AnyDesk - as medium gray color for breadcrumbs
$gray-medium-breadcrumbs:       #dbdbdb; // created by AnyDesk - as medium gray color for breadcrumbs
$gray-dark-blue:                #332e45; // created by AnyDesk - as background-color for license-configurator sidebox
$gray-lighter:                  #fbfbfb; // created by AnyDesk - as background-color for license-configurator content

$primary:       $red !default;
$secondary:     $red-light !default;
$info:          $blue !default;
$success:       $green !default;

$primary-light:     $red-light !default; // created by AnyDesk
$primary-medium:    $red-medium !default; // created by AnyDesk

$primary-orange-gradient-bg:              linear-gradient(90deg, $primary 0%, $orange 100%); // created by AnyDesk
$primary-orange-gradient-border:          linear-gradient(0.25turn, $primary, $orange); // created by AnyDesk
$primary-orange-gradient-border-180deg:   linear-gradient(180deg, $primary, $orange); // created by AnyDesk
$primary-orange-gradient-text:            -webkit-linear-gradient(45deg, $primary, $orange); // created by AnyDesk
$primary-orange-white-gradient-text:      -webkit-linear-gradient(0deg, white 0%, $orange 50%, $primary 100%); // created by AnyDesk
$primary-orange-black-gradient-text:      -webkit-linear-gradient(0deg, black 0%, $primary 50%, $orange 100%); // created by AnyDesk



// Body Color

$body-color:                $gray-font !default;


// Background Color

$bg-color-white-transparent:            linear-gradient(0deg, rgba(255,255,255,0.75) 0%, rgba(255,255,255,0.75) 100%);
$bg-color-white-transparent-light:      linear-gradient(0deg, rgba(255,255,255,0.45) 0%, rgba(255,255,255,0.45) 100%);
$bg-color-black-transparent:            linear-gradient(0deg, rgba(26,0,13,0.80) 0%, rgba(0,5,25,0.80) 100%);
$bg-color-black-transparent-semilight:  linear-gradient(0deg, rgba(26,0,13,0.60) 0%, rgba(0,5,25,0.60) 100%);
$bg-color-black-transparent-light:      linear-gradient(0deg, rgba(26,0,13,0.30) 0%, rgba(0,5,25,0.30) 100%);


// Fonts

$line-height-base:            1.8 !default;
$headings-font-weight:        300 !default;


// Buttons

$input-btn-padding-y:         .875rem !default;
$input-btn-padding-x:         1.75rem !default;


// Spacer

$spacer: 2rem !default;


// Carousel

$carousel-control-color:            $primary !default;
$carousel-indicator-active-bg:      $primary !default;


// Jumbotron

$jumbotron-bg:                     transparent !default;


// Container

$container-padding-y:     5rem; // created by AnyDesk

$container-mt :  -100px;

//H1

$header-mb :   100px;

// Grid containers

$container-max-widths: (
  sm: 540px,
  md: 800px,
  lg: 1140px,
  xl: 1400px
) !default;


// Bootstrap Select

$color-red-error: rgb(185, 74, 72) !default;
$color-green-success: #28a745;
$color-grey-arrow: rgba(204, 204, 204, 0.2) !default;

$width-default: 220px !default; // 3 960px-grid columns

$zindex-select-dropdown: 1060 !default; // must be higher than a modal background (1050)

//** Placeholder text color
$input-color-placeholder: #999 !default;
$input-alt-color-placeholder: rgba(255, 255, 255, 0.5) !default;

$input-padding-y-sm: .25rem !default;
$input-padding-x-sm: .5rem !default;

$input-padding-y-lg: 0.5rem !default;
$input-padding-x-lg: 1rem !default;