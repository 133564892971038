//// AnyDesk main scss file, contains everything we need on all sites.

// AnyDesk file: Variables before Bootstrap Variables
@import "variables_before";

// Bootstrap files
@import "bootstrap/functions";
@import "bootstrap/variables";

// AnyDesk file: Variables after Bootstrap Variables
@import "variables_after";

// Additional Bootstrap files (Comment out what we don't need)
@import "bootstrap/mixins";
@import "bootstrap/root";
@import "bootstrap/reboot";
@import "bootstrap/type";
@import "bootstrap/images";
//@import "bootstrap/code";
@import "bootstrap/grid";
@import "bootstrap/tables";
@import "bootstrap/forms";
@import "bootstrap/buttons";
@import "bootstrap/transitions";
@import "bootstrap/dropdown";
//@import "bootstrap/button-group";
@import "bootstrap/input-group";
//@import "bootstrap/custom-forms";
@import "bootstrap/nav";
@import "bootstrap/navbar";
@import "bootstrap/card";
//@import "bootstrap/breadcrumb";
//@import "bootstrap/pagination";
//@import "bootstrap/badge";
@import "bootstrap/jumbotron";
@import "bootstrap/alert";
//@import "bootstrap/progress";
//@import "bootstrap/media";
//@import "bootstrap/list-group";
@import "bootstrap/close";
@import "bootstrap/modal";
//@import "bootstrap/tooltip";
@import "bootstrap/popover";
//@import "bootstrap/carousel";
@import "bootstrap/utilities";
//@import "bootstrap/print";

// Additional AnyDesk files
@import "reboot";			// our links
@import "type";				// our main typography
@import "grid";				// our grid
@import "buttons";			// our buttons
@import "navbar";			// our main and meta navbar //TODO split this maybe?
@import "card";				// our cards
@import "jumbotron";		// our jumbotrons
@import "alert";		    // our alerts
@import "utilities";		// some global stuff //TODO not sure if order matters? move to top maybe?
@import "dropdown"; 	    // our dropdown menus
@import "forms";			// form elements //TODO split this somehow into something more reusable?
@import "modal";			// our modals
@import "nav";				// icon nav for download page
@import "popover";			// our popovers
@import "list-group";		// our list groups
@import "transitions";		// our transitions
@import "input-group";      // our input groups
@import "animate";          // our animations
@import "tables";           // our tables

@import "ad_base";
@import "ad_layout";
@import "ad_module_global";
@import "ad_module_ad-www";
@import "ad_flags";
