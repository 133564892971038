////// AnyDesk Base File: It contains all Base Code

//// Dark Mode

.dark-theme {
  @extend .navbar-light;
  color: white;

  // Base

  body {
    background-color: $gray-dark;
  }

  ul {
    color: white;
  }

  p, span, div {
    color: white;
  }

  .any-link {
    color: $primary;
  }

  hr {
    border-top: 1px solid $gray;
  }

  .container-white, .container-nav-bg {
    background-color: transparent !important;
  }

  // Type

  p, h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, .h3-4 {
    color: white;
  }

  // Layout

  .any-overlay-white {
    background: $bg-color-black-transparent;
  }

  .any-overlay-white-light {
    background: $bg-color-black-transparent-semilight;
  }

  // Buttons

  .btn {
    &:active {
      box-shadow: 0 0 10px 3px $white;
      &::after {
        box-shadow: 0 0 0 0 $white;
      }
    }

    &:after {
      box-shadow: 0 0 5px 15px $white;
    }
  }

  .btn-white-onlyDarkmode {
    background-color: $white;
    color: $primary;
    background-image: none;

    &:hover {
      background-image: linear-gradient(to right, $primary 0%, $primary  51%, $orange  100%);

      p {
        color: $white !important;
        -webkit-text-fill-color: unset;
        background: none;
      }
    }

    &:active {
      background-image: none !important;
      color: $primary;
      border-color: $red-mega-light;
    }

    p {
      @extend .primary-orange-gradient;
    }
  }

  // NavBar

  .navbar-bg {
    background: $gray-dark;
  }

  .navbar {
    .navbar-logo {
      .any-logo-black {
        display: none !important;
      }

      .any-logo-white {
        display: block !important;
      }

      .partner-logo {
        background-color: white;
        padding: 3px;
        border-radius: 3px;
        height: 25px;
      }
    }

    .navbar-toggler {
      .icon-bar {
        background: white;
      }
    }

    .navbar-collapse {
      .navbar-nav {
        background-color: transparent;

        .nav-item {
          border-bottom: 1px solid $gray-font;

          @media (min-width: 992px) {
            border-bottom: none;
          }
        }

        .nav-link {
          color: white !important;

          &:hover {
            background: $gray-dark;

            @media (min-width: 992px) {
              background: transparent;
            }
          }
        }

        .nav-link-arrow-right {
          &::after {
            background-image: url(../../_static/img/icons/streamline/arrows-diagrams/arrows/arrow-right-1-white.svg);
          }
        }

        .dropdown-menu {
          background-color: $gray-dark;

          .dropdown-item {
            color: white;
          }
        }
      }
    }
  }

  .navbar-light {
    &.nav-up, &.nav-down {
      .change-theme-text {
        color: #747474;
      }

      @media (min-width: 992px) {
        .navbar {
          .navbar-collapse {
            .navbar-nav {
              .nav-link {
                .menu-icon-arrow-down-white {
                  display: inline-block !important;
                }
              }
            }
          }
        }
      }
    }
  }

  .nav-up, .nav-down {
    @media (min-width: 992px) {
      background-color: $gray-dark;

      .navbar {
        .navbar-logo {
          .any-logo-black {

            display: none !important;
          }

          .any-logo-white {
            display: inline-block !important;
          }
        }
      }

      .nav-link {
        color: white !important;

        .menu-icon-arrow-down-white {
          display: inline-block !important;
        }

        .menu-icon-arrow-down-black {
          display: none !important;
        }
      }

      .nav-partner-text {
        color: white;
      }
    }
    .any-logo-white-made {
      display: inline !important;
    }
  }

  // Nav

  .nav-tabs {
    .nav-link {
      &.active {
        background-color: $gray-dark;
      }
    }
  }

  .nav-pills {
    border: 2px solid $body-color;

    .nav-link {
      color: white;

      &.active {
        background-color: $gray-dark;
      }
    }
  }

  #iconNav {
    .nav-link {
      &.active {
        background-color: $gray-dark;
      }

      p {
        color: $gray;
      }
    }
  }

  #licenseNav {
    .nav-tabs {
      .nav-item {
        .nav-link {
          background: $gray-darklight;
          color: white;
          border: 1px solid $body-color;

          &.active {
            background: $primary-orange-gradient-bg;
            border: none;
          }
        }
      }
    }
  }

  .any-logo-black-made {
    display: none !important;
  }

  .any-logo-white-made {
    display: inline !important;
  }

  // Modals

  .modal {

    .modal-custom {
      background-color: $gray-darker !important;
    }

    .modal-content {
      border: 1px solid $body-color;

      .modal-header {
        background-color: $gray-darker;
      }
    }
  }

  .modal-contact {
    .modal-content {
      border: none;

      .modal-header {
        background-color: transparent;
      }

      .modal-body {
        background-color: $gray-darker;
      }
    }
  }

  .modal-contact-form {
    .modal-content {
      .modal-body {
        form {
          color: white;

          p {
            color: white;
          }
        }
      }
    }
  }

  .modal-video {
    .modal-content {
      border: none;

      .modal-header {
        background-color: transparent;
      }
    }
  }

  .modal-licenses {
    .modal-dialog {
      .modal-content {
        background: $gray-darker;

        .modal-header {
          button {
            span {
              background-image: url(../../_static/img/icons/streamline/interface-essential/form-validation/close-white.svg);
            }
          }
        }
      }
    }
  }

  // Dropdown

  .dropdown {
    .dropdown-menu {
      background-color: $gray-dark;
    }

    .dropdown-toggle {
      &::after {
        background-image: url(../../_static/img/icons/streamline/arrows-diagrams/arrows/arrow-down-1-white.svg);
      }
    }
  }

  .language-select {
    .dropdown-menu {
      border: 1px solid $gray-darker;
    }
    .dropdown-menu.inner {
      border: none;
    }

    .dropdown-toggle {
      &::after {
        background-image: none;
      }
    }
  }

  .dropdown-menu {
    border-top: 1px solid $gray-font;

    @media (min-width: 992px) {
      border: 1px solid $gray-font;
    }

    .dropdown-category {
      border-bottom: 1px solid $gray-font;
    }
  }

  // Grid

  .container-gray {
    background-color: $gray-darker;
  }

  .container-gray-lighter {
    background-color: $gray-darker;
  }


  // Modules

  .any-alertWarning {
    span {
      color: #856404 !important;
    }
  }

  .any-module-imgBox {
    border: 1px solid $gray-font;
  }

  .any-module-dateImgBox {
    border: 1px solid $gray-font;
  }

  .any-module-pricingTable {

    .any-module-pricingTable-heading {
      color: white !important;
    }

    .any-module-pricingTable-price,
    .any-module-pricingTable-originalprice {
      color: white !important;
    }

    .any-module-pricingTable-description {
      border-bottom: none;
    }
  }

  .any-module-pricingTableHighlighted {
    border: 2px solid $primary;
    background-color: $gray-darklight;
  }

  .any-module-squareHoverBox {
    border: 2px solid #161616;
    background-color: $gray-dark;
  }

  .any-module-circleHoverImg {
    img {
      border: 2px solid $gray-font;
    }

    p {
      color: white;
    }
  }

  .any-module-servicesTable {
    .any-module-servicesTable-heading {
      border: 2px solid $primary;
    }

    .any-module-servicesTable-content {
      border-right: 5px solid $gray-dark;
    }
  }

  .any-module-hashBadge {
    background-color: $gray-dark;
  }

  .any-module-textImgQuote {
    .any-module-textImgQuote-name {
      background-color: $gray-dark;

      p {
        color: $primary;
      }
    }
  }

  .any-module-textimg {
    .any-module-textvideo-img {
      border: none;
    }
  }

  .any-featuresListMobile {
    .table-striped {
      thead {
        th {
          border: 1px solid $gray-font;
          background-color: $gray-darklight !important;
        }
      }

      tbody {
        tr:nth-of-type(odd) {
          background-color: $gray-dark !important;
          border: 1px solid $gray-font;
        }

        th {
          background-color: $gray-darklight !important;
          border: 1px solid $gray-font;
        }

        td {
          border: 1px solid $gray-font;
        }
      }
    }
  }

  .any-floatBoxFixedRight {
    span {
      color: $gray-font;
    }
  }

  .embed-responsive {
    border: 1px solid $gray-font;
  }

  .any-module-shadowHoverBox {
    &:hover {
      @extend .any-boxshadow-left-right;
      background: $gray-darker;

      h4 {
        color: $primary !important;
      }
    }
  }


  // Forms

  .form-control {
    background-color: $gray-darker;
    border: 1px solid $gray-font;
    color: white;

    option {
      background-color: $gray-darker;
    }
  }

  .input-group-append {
    button {
      background: $gray-darker;
    }
  }

  [type="radio"]:checked + label,
  [type="radio"]:not(:checked) + label {
    color: white;
  }

  [type="radio"]:checked + label:before,
  [type="radio"]:not(:checked) + label:before {
    background: $gray-dark;
    border: 1px solid $gray-font;
  }

  [type="radio"]:checked + label:after,
  [type="radio"]:not(:checked) + label:after {
    border: 2px solid $gray-dark;
  }

  [type="checkbox"] + label:before {
    background-color: $gray-dark;
    border: 1px solid $gray-font;
  }

  [type="checkbox"]:checked + label:before {
    background-color: #3C3C3C;
    border: 1px solid $gray;
  }

  [type="checkbox"] + label:after {
    background-color: #BABABA;
    box-shadow: none;
    border: 1px solid #BABABA;
  }

  .any-checkbox-darkmode {
    .gray-medium {
      color: #747474 !important;
    }
  }

  // Cards

  .card-accordion {
    .card {
      .card-header {
        &.collapsed {
          .card-link {
            color: white;

            h5 {
              color: white;
            }
          }
        }

        &.expanded {
          .card-link {
            h5 {
              color: $primary;
            }
          }
        }

        .card-link {
          .collapsed {
            .accordion-icon {
              background-image: url(../../_static/img/icons/streamline/arrows-diagrams/arrows/arrow-down-1-white.svg);
            }
          }

          .h3-4 {
            color: white !important;
          }

          h5 {
            color: $primary;
          }
        }
      }

      .card-body {
        .nav-link {
          color: white;
        }
      }
    }
  }

  .card-accordion-info {
    .card {
      background-color: rgba(255, 255, 255, .2);
      .card-header {
        .card-link {
          h5 {
            color: white !important;
          }
        }
      }
    }
  }

  // Jumbotrons

  .jumbotron {
    border: 2px solid $body-color;
  }

  .jumbotron-icon {
    .jumbotron-icon-img-left,
    .jumbotron-icon-img-right {
      background-color: $gray-dark;
    }
  }

  // Utilities

  .bg-color-gray-transparent {
    background-color: rgba(32, 32, 32, 0.7);
  }

  .bg-white {
    background-color: $gray-darklight !important;
    border: 1px solid $gray-font;
  }

  .bg-white-transparent {
    background-color: rgba(255,255,255,0.1);
  }

  .border-solid-gray {
    border: 1px solid $gray-font;
  }

  .border-bottom-solid-gray {
    border-bottom: 1px solid $gray-font !important;
  }

  .border-solid-gradient-2px-radius {
    background: conic-gradient($gray-darker 0 0) padding-box, linear-gradient(45deg,$primary,$orange) border-box !important;
  }

  .bg-color-gray {
    background-color: $gray-darker;
  }

  .bg-color-gray-sm {
    @media (max-width: 575px) {
      background-color: $gray-darker;
    }
  }

  .bg-color-gray-gradient {
    background: linear-gradient(#1A1A1A, #585858);
  }

  .font-color {
    color: white;
  }

  .border-top-solid-gray-light-5px {
    border-top: 5px solid $gray;
  }

  .border-top-solid-gray-medium-5px {
    border-top: 5px solid $gray-font;
  }

  .border-top-solid-gray-5px {
    border-top: 5px solid $gray-dark;
  }

  .border-top-solid-gray-darker-5px {
    border-top: 5px solid $black;
  }

  .border-top-solid-gray-medium-2px {
    border-top: 2px solid $gray-font;
  }

  .border-top-solid-gray-light-2px {
    border-top: 2px solid $gray;
  }

  .border-top-solid-gray-2px {
    border-top: 2px solid $gray-dark;
  }

  .border-solid-gradient-5px-animated {
    background: linear-gradient($gray-dark, $gray-dark) padding-box, linear-gradient(var(--angle), $primary, $orange) border-box;
  }

  //// Colours

  .blue-dark {
    color: $blue-dark;
  }

  .primary-orange-black-gradient {
    background: $primary-orange-white-gradient-text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  // Sliders

  .any-slider-slick-client-testimonials {
    .any-slider {
      background-color: $gray-dark;
    }

    .slick-prev {
      &::before {
        background-image: url(../../_static/img/icons/streamline/arrows-diagrams/arrows/arrow-left-1-white.svg);
      }
    }

    .slick-next {
      &::before {
        background-image: url(../../_static/img/icons/streamline/arrows-diagrams/arrows/arrow-right-1-white.svg);
      }
    }
  }


  // Alerts

  .alert-danger {
    div {
      color: $primary;
    }
  }


  // Popover

  .popover {
    background-color: $gray-font;
  }

  .bs-popover-auto[x-placement^=right] {
    margin-left: 1rem;

    .arrow {
      &::before {
        border-right-color: $gray-font !important;
      }

      &::after {
        border-right-color: $gray-font !important;
      }
    }
  }

  .bs-popover-right {
    margin-left: 1rem;

    .arrow {
      &::before {
        border-right-color: $gray-font !important;
      }

      &::after {
        border-right-color: $gray-font !important;
      }
    }
  }

  .bs-popover-auto[x-placement^=left] {
    margin-left: 1rem;

    .arrow {
      &::before {
        border-left-color: $gray-font !important;
      }

      &::after {
        border-left-color: $gray-font !important;
      }
    }
  }

  .bs-popover-left {
    margin-left: 1rem;

    .arrow {
      &::before {
        border-left-color: $gray-font !important;
      }

      &::after {
        border-left-color: $gray-font !important;
      }
    }
  }

  .bs-popover-auto[x-placement^=bottom] {
    margin-left: 1rem;

    .arrow {
      &::before {
        border-bottom-color: $gray-font !important;
      }

      &::after {
        border-bottom-color: $gray-font !important;
      }
    }
  }

  .bs-popover-bottom {
    margin-left: 1rem;

    .arrow {
      &::before {
        border-bottom-color: $gray-font !important;
      }

      &::after {
        border-bottom-color: $gray-font !important;
      }
    }
  }

  .bs-popover-auto[x-placement^=top] {
    margin-left: 1rem;

    .arrow {
      &::before {
        border-top-color: $gray-font !important;
      }

      &::after {
        border-top-color: $gray-font !important;
      }
    }
  }

  .bs-popover-top {
    margin-left: 1rem;

    .arrow {
      &::before {
        border-top-color: $gray-font !important;
      }

      &::after {
        border-top-color: $gray-font !important;
      }
    }
  }


  // Breadcrumbs

  .any-circleNumber {
    background-color: $gray-darklight;
    color: $primary;
  }

  .any-breadcrumbs-circle {
    .circle-active {
      background-color: $primary;
      color: white;
    }

    .circle-inactive {
      background-color: $gray-darklight;
      border: 2px solid $gray-darklight;
      color: $gray-font;
    }

    .circle-done {
      background-color: $gray-darklight;
    }

    .line-inactive {
      border-top: 2px solid $gray-darklight;
    }
  }

  // LICENSE CONFIGURATOR

  #expand-question {
    &.any-overlay-white {
      background: rgba(16, 16, 16, 0.85);
    }

    .bg-color-white {
      background-color: $gray-darklight;
    }
  }


  // TRANSLATION TOOL

  #tr-icon, #tr-menu div, #tr-menu li, .tr-modal div, #tr-edit-key {
    color: $body-color;
  }


  // SUPERCHARGED

  .table-supercharged {
    tbody tr:nth-of-type(odd) {
      background: rgb(0,0,0);
      background: linear-gradient(90deg, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 7%, rgba(0,0,0,1) 100%);
    }

    tr {
      th {
        border-bottom: 1px solid $gray-darklight;
      }

      th.border-bottom-solid-white-3px {
        border-bottom: 3px solid $gray-darklight !important;
      }

      td {
        border-bottom: 1px solid $gray-darklight;
      }
    }
  }


  // HOME PAGE IMAGE

  .home-animation-text {
    color: $primary;
  }


  // CONTENT BOX MODULE

  .any-module-contentBox {
    border: 1px solid white;
  }

  .any-module-contentBox-redBorder {
    border: 1px solid $primary;
  }

  .any-module-contentBox-gradientBorder {
    border-image: conic-gradient(from var(--angle), $primary 50%, $orange 70%, $orange 92%, $primary 100%) 1;
  }

}


//// Lists

// Normal List

ul, ol {
  text-align: left;
  padding-left: 18px;
  margin-bottom: 0;

  li {
    line-height: 1.5;
    margin-bottom: 12px;

    &::marker {
      color: $primary;
    }
  }
}


.any-list {
  list-style: none;
  padding-left: 0;
  margin-bottom: 2rem;
  margin-top: 1rem;

  li {
    margin-bottom: 8px;
    margin-left: 30px;

    &:before {
      content: '';
      margin-left: -32px;
    }

    &:last-child {
      margin-bottom: 0;
    }

    img {
      margin-bottom: 7px;
    }

    ul {
      padding-left: 0;
      margin-top: 10px;

      li {
        margin-bottom: 0;
      }
    }
  }
}

.any-list-sm {
  li {
    margin-bottom: 0;
  }
}

.any-list-centerAlign {
  list-style: inside;
  text-align: center;
  padding-left: 0;

  li {
    margin-bottom: 8px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

// Icon List (Download Changelog)

.any-list-icon {
  list-style: none;
  padding: 0;
  margin: 0;

  li {
    margin-bottom: 15px;
    margin-left: 47px;

    img {
      margin-left: -47px;
    }

    .any-list-icon-text {
      padding-left: 12px;
    }
  }
}


//// Icons

.any-icon-xxxs {
  height: 12px;
}

.any-icon-xxs {
  height: 15px;
}

.any-icon-xs {
  height: 20px;
}

.any-icon-xssm {
  height: 28px;
}

.any-icon-sm {
  height: 35px;
}

.any-icon-md {
  height: 50px;
}

.any-icon {
  height: 70px;
}

.any-icon-lg {
  height: 50px;

  @media (min-width: 576px) {
    height: 70px;
  }

  @media (min-width: 768px) {
    height: 90px;
  }
}

// Display

.any-d-none-xxl {
  display: block;

  @media (min-width: 1920px) {
    display: none;
  }
}

.any-d-block-xxl {
  display: none;

  @media (min-width: 1920px) {
    display: block;
  }
}

//// Breadcrumbs & Circle

.any-circleNumber {
  text-align: center;
  position: relative;
  z-index: 1;
  display: inline-block;
  width: 40px;
  height: 40px;
  padding: 3px;
  border-radius: 50%;
  font-weight: bold;
  font-size: 18px;
  background-color: white;
  border: 2px solid $primary;
  color: $primary;
  cursor: default;

  @media (min-width: 576px) {
    width: 50px;
    height: 50px;
    padding: 6px;
    font-size: 22px;
  }
}

.any-breadcrumbs-circle {
  .circle {
    @extend .any-circleNumber;
  }

  .circle-active {
    @extend .circle;
    @extend .any-boxshadow;
    background: $primary-orange-gradient-bg;
    border: 0;
    color: white;
    width: 50px;
    height: 50px;
    font-size: 22px;
    padding: 5px;

    @media (min-width: 576px) {
      width: 60px;
      height: 60px;
      padding: 4px;
      font-size: 29px;
    }
  }

  .circle-inactive {
    @extend .circle;
    background-color: $gray-light-breadcrumbs;
    color: $gray-medium-breadcrumbs;
    border: 2px solid $gray-light-breadcrumbs;

    @media (min-width: 576px) {
      padding: 5px;
    }
  }

  .circle-done {
    @extend .circle;
    padding: 5px;
    cursor: pointer;

    img {
      width: 20px;

      @media (min-width: 576px) {
        width: 25px;
      }
    }
  }

  .line {
    display: inline-block;
    width: 18px;
    height: 9px;
    margin-left: -5px;
    margin-right: -5px;

    @media (min-width: 576px) {
      width: 65px;
    }
  }

  .line-active {
    @extend .line;
    border-top: 2px solid $primary;
  }

  .line-inactive {
    @extend .line;
    border-top: 2px solid $gray-light-breadcrumbs;

  }
}

.any-breadcrumbs-link {
  padding: 0 0 10px 5px;
  font-size: 20px;

  a {
    border: none;
    color: $gray-lighter-breadcrumbs;
    font-weight: 300;

    &:hover {
    }
  }

  .line {
    display: inline-block;
    width: 18px;
    height: 6px;

    @media (min-width: 576px) {
      width: 45px;
    }
  }

  .line-inactive {
    @extend .line;
    border-top: 2px solid $gray-lighter-breadcrumbs;
    margin: 0 15px;
  }
}