////// AnyDesk Forms

//// Standard Form

.form-control {
    border: 1px solid $gray-light-60;
    border-radius: 6px;
    color: $body-color;
    padding: 0.45rem 1.2rem;
}

.form-control-sm {
  padding: 0.45rem 1rem;
}

.form-control::placeholder {
    color: $gray-light-60 !important;
}

.form-control-focused {
  //background-color: #fff;
  outline: 0;
  //box-shadow: 0 0 0 .2rem rgba(239, 68, 59, 0.25);
  @extend .border-solid-gradient-2px-radius;
}

textarea.form-control {
    border-radius: 6px;
}

label {
  margin-bottom: 0;
}


//// Form Check

.form-check {
    padding-left: 0;
}

.form-check-input {
    height: 19px;
}


//// Select & Input

select.form-control, input.form-control {
  height: 44px !important;
}

select.form-control-sm, input.form-control-sm {
  height: 40px !important;
}


select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    font-weight: 700;

    option {
      &:first-child {
        font-weight: 700;
      }
    }
}

.any-selectArrow {
    width: 16px;
    height: 16px;
    position: absolute;
    display: inline-block;
    right: 1px;
    top: 1px;
    pointer-events: none;

    @media (min-width: 992px) and (max-width: 1200px) {
      right: 0;
    }

    img {
      margin-bottom: 2px;
      margin-right: 0px;

      @media (min-width: 992px) {
        margin-right: 2px;
      }
    }
}

.any-selectArrowHighlighted {
    width: 63px;
    height: 56px;
    position: absolute;
    display: inline-block;
    right: 0;
    top: -6px;
    background: transparent;
    border-radius: 50px;
    pointer-events: none;

    img {
        margin-top: 15px;
        margin-left: 22px;
    }
}

.any-selectArrowHighlighted-sm {
  height: 35px;
  top: 1px;

  img {
    margin-top: 7px;
    margin-left: 30px;
  }
}


// Custom file input.

.custom-file {
    position: relative;
    display: inline-block;
    width: 100%;
    height: $custom-file-height;
    margin-bottom: 10px;

  
  .custom-file-input {
    position: relative;
    z-index: 2;
    width: 100%;
    height: $custom-file-height;
    margin: 0;
    opacity: 0;
  
    &:focus ~ .custom-file-control {
      border-color: $custom-file-focus-border-color;
      box-shadow: $custom-file-focus-box-shadow;
  
      &::before {
        border-color: $custom-file-focus-border-color;
      }
    }
  
    @each $lang, $value in $custom-file-text {
      &:lang(#{$lang}) ~ .custom-file-label::after {
        content: $value;
      }
    }
  }
  
  .custom-file-label {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1;
    height: $custom-file-height;
    padding: $custom-file-padding-y $custom-file-padding-x;
    line-height: $custom-file-line-height;
    color: $custom-file-color;
    background-color: $custom-file-bg;
    border: $custom-file-border-width solid $custom-file-border-color;
    @include border-radius($custom-file-border-radius);
    @include box-shadow($custom-file-box-shadow);
  
    &::after {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      z-index: 3;
      display: block;
      height: calc(#{$custom-file-height} - #{$custom-file-border-width} * 2);
      padding: $custom-file-padding-y $custom-file-padding-x;
      line-height: $custom-file-line-height;
      color: $custom-file-button-color;
      content: "Browse";
      @include gradient-bg($custom-file-button-bg);
      border-left: $custom-file-border-width solid $custom-file-border-color;
      @include border-radius(0 $custom-file-border-radius $custom-file-border-radius 0);
    }
  }
}

.custom-control {
    padding-left: 0;
}

  
// Input hide placeholder

input:focus::-webkit-input-placeholder { color:transparent; }
input:focus:-moz-placeholder { color:transparent; } /* FF 4-18 */
input:focus::-moz-placeholder { color:transparent; } /* FF 19+ */
input:focus:-ms-input-placeholder { color:transparent; } /* IE 10+ */


// IE 11 Hacks

_:-ms-fullscreen, :root {
    .form-control {
        padding: 14px 30px 21px;
    }

    select.form-control {
        padding: 0 30px 0;
    }

    .form-jointFieldButton {
        .form-group {
            @media (min-width: 576px) {
                display: block;
            }

            &:first-child {
                @media (min-width: 576px) {
                    margin-right: 0;
                }
            }
        }

        input.form-control {
            @media (min-width: 576px) {
                border-right: 1px solid #ced4da !important;
                border-top-right-radius: 0.25rem !important;
                border-bottom-right-radius: 0.25rem !important;
                margin: 0 auto;
            }
        }

        button {
            @media (min-width: 576px) {
                border-top-left-radius: 0.25rem;
                border-bottom-left-radius: 0.25rem;
            }
        }
    }
}

select::-ms-expand {
    width:12px;
    border:none;
    background:#fff;
}


//// Icon Inside of Form

.input-icon {
    position: absolute; 
    top: 6px;
    right: 15px;
    float: right;
    border: none;
}


//// Radio buttons design

[type="radio"]:checked,
[type="radio"]:not(:checked) {
    position: absolute;
    left: -9999px;
}

[type="radio"]:checked + label,
[type="radio"]:not(:checked) + label {
    position: relative;
    padding-left: 32px;
    cursor: pointer;
    display: inline-block;
    color: $body-color;
}

[type="radio"]:checked + label:before,
[type="radio"]:not(:checked) + label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 1.5em;
    height: 1.5em;
    border: 1px solid $gray-medium;
    background: $gray-light;
    border-radius: 50%;
}

[type="radio"]:checked + label:after,
[type="radio"]:not(:checked) + label:after {
    content: '';
    width: 1em;
    height: 1em;
    left: 4px;
    top: 4px;
    background: $primary;
    position: absolute;
    border-radius: 50%;
    border: 2px solid $gray-light;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
}

[type="radio"]:not(:checked) + label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
}

[type="radio"]:checked + label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
}


// Checkbox design

[type="checkbox"]:not(:checked),
[type="checkbox"]:checked {
    position: absolute;
    left: -9999px;
}

[type="checkbox"] {
  position: relative;
  left: 30px;
  top: 0px;
  z-index: 0;
  -webkit-appearance: none;
}

[type="checkbox"] + label {
  position: relative;
  display: block;
  cursor: pointer;
  padding-left: 59px;
}

[type="checkbox"] + label:before {
  width: 50px;
  height: 26px;
  border-radius: 30px;
  border: 1px solid $gray-medium;
  content: "";
  background-color: $gray-light;
  margin-right: 15px;
  @extend .any-transition-fade;
  z-index: 5;
  position: absolute;
  left: 0px;
}

[type="checkbox"] + label:after {
  width: 24px;
  height: 24px;
  border-radius: 30px;
  background-color: white;
  content: "";
  transition: margin 0.1s linear;
  border: 1px solid $gray-medium;
  position: absolute;
  left: 1px;
  top: 1px;
  z-index: 10;
}

[type="checkbox"]:checked + label:before {
  background-color: $primary;
  border: 1px solid white;
}

[type="checkbox"]:checked + label:after {
  margin: 0 0 0 24px;
}


// Darkmode Checkbox

.any-checkbox-darkmode {
  display: inline-block;
  width: 80%;
  position: relative;

  @media (min-width: 768px) {
    width: auto;
  }

  .change-theme-text {
    text-align: left;
  }

  .moon {
    position: absolute;
    top: 6px;
    left: 25px;
    z-index: 1000;
    pointer-events: none;
    width: 13px;
    height: 13px;
    background-color: transparent;
    border-radius: 50%;
    box-shadow: 5px 0px 0px 0px #909090;
  }
}


// HubSpot Form

#hs-form-iframe-0 {
    .hs-form-iframe {
        .hbspt-form {
            label {
                font-weight: 400 !important;
                line-height: 1.2 !important;
                font-size: 1rem !important;
            }
        }
    }
}


// Bootstrap Select

@keyframes bs-notify-fadeOut {
  0% {opacity: 0.9;}
  100% {opacity: 0;}
}

// Mixins
@mixin cursor-disabled() {
  cursor: not-allowed;
}

@mixin box-sizing($fmt) {
  -webkit-box-sizing: $fmt;
     -moz-box-sizing: $fmt;
          box-sizing: $fmt;
}

@mixin box-shadow($fmt) {
  -webkit-box-shadow: $fmt;
          box-shadow: $fmt;
}

@function fade($color, $amnt) {
  @if $amnt > 1 {
    $amnt: $amnt / 100; // convert to percentage if int
  }
  @return rgba($color, $amnt);
}

// Rules
select.bs-select-hidden,
.bootstrap-select > select.bs-select-hidden,
select.selectpicker {
  display: none !important;
}

.bootstrap-select {
  width: 220px \0; /*IE9 and below*/
  vertical-align: middle;

  // The selectpicker button
  > .dropdown-toggle {
    position: relative;
    width: 100%;
    // necessary for proper positioning of caret in Bootstrap 4 (pushes caret to the right)
    text-align: right;
    white-space: nowrap;
    // force caret to be vertically centered for Bootstrap 4 multi-line buttons
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    background-color: transparent;
    color: $primary;
    border: 0 !important;
    padding: 2px;
    font-weight: 400;
    margin-right: 2px;
    @extend .btn-sm;

    &:after {
      margin-top: -1px;
    }

    &.bs-placeholder {
      &,
      &:hover,
      &:focus,
      &:active {
        color: $input-color-placeholder;
      }

      &.btn-primary,
      &.btn-secondary,
      &.btn-success,
      &.btn-danger,
      &.btn-info,
      &.btn-dark {
        &,
        &:hover,
        &:focus,
        &:active {
          color: $input-alt-color-placeholder;
        }
      }
    }
  }

  > select {
    position: absolute !important;
    bottom: 0;
    left: 50%;
    display: block !important;
    width: 0.5px !important;
    height: 100% !important;
    padding: 0 !important;
    opacity: 0 !important;
    border: none;
    z-index: 0 !important;

    &.mobile-device {
      top: 0;
      left: 0;
      display: block !important;
      width: 100% !important;
      z-index: 2 !important;
    }
  }

  // Error display
  .has-error & .dropdown-toggle,
  .error & .dropdown-toggle,
  &.is-invalid .dropdown-toggle,
  .was-validated & select:invalid + .dropdown-toggle {
    border-color: $color-red-error;
  }

  &.is-valid .dropdown-toggle,
  .was-validated & select:valid + .dropdown-toggle {
    border-color: $color-green-success;
  }

  &.fit-width {
    width: 135px !important;
  }

  &:not([class*="col-"]):not([class*="form-control"]):not(.input-group-btn) {
    width: $width-default;
  }

  > select.mobile-device:focus + .dropdown-toggle,
  .dropdown-toggle:focus {
    outline: thin dotted #333333 !important;
    outline: 5px auto -webkit-focus-ring-color !important;
    outline-offset: -2px;
  }

  &.show {
    .dropdown-toggle {
      background-color: transparent;
      color: $primary;
    }
  }
}

// The selectpicker components
.bootstrap-select.fit-width {
  width: 50px !important;
}

.bootstrap-select {
  padding: 0;
  margin-right: -20px;

  &.form-control {
    margin-bottom: 0;
    padding: 0;
    border: none;
    height: auto;

    :not(.input-group) > &:not([class*="col-"]) {
      width: 100%;
    }

    &.input-group-btn {
      float: none;
      z-index: auto;
    }
  }

  .form-inline &,
  .form-inline &.form-control:not([class*="col-"]) {
    width: auto;
  }

  &:not(.input-group-btn),
  &[class*="col-"] {
    float: none;
    display: inline-block;
    margin-left: 0;
  }

  // Forces the pull to the right, if necessary
  &,
  &[class*="col-"],
  .row &[class*="col-"] {
    &.dropdown-menu-right {
      float: right;
    }
  }

  .form-inline &,
  .form-horizontal &,
  .form-group & {
    margin-bottom: 0;
  }

  .form-group-lg &.form-control,
  .form-group-sm &.form-control {
    padding: 0;

    .dropdown-toggle {
      height: 100%;
      font-size: inherit;
      line-height: inherit;
      border-radius: inherit;
    }
  }

  &.form-control-sm .dropdown-toggle,
  &.form-control-lg .dropdown-toggle {
    font-size: inherit;
    line-height: inherit;
    border-radius: inherit;
  }

  &.form-control-sm .dropdown-toggle {
    padding: $input-padding-y-sm $input-padding-x-sm;
  }

  &.form-control-lg .dropdown-toggle {
    padding: $input-padding-y-lg $input-padding-x-lg;
  }

  // Set the width of the live search (and any other form control within an inline form)
  // see https://github.com/silviomoreto/bootstrap-select/issues/685
  .form-inline & .form-control {
    width: 100%;
  }

  &.disabled,
  > .disabled {
    @include cursor-disabled();

    &:focus {
      outline: none !important;
    }
  }

  &.bs-container {
    position: absolute;
    top: 0;
    left: 0;
    height: 0 !important;
    padding: 0 !important;

    .dropdown-menu {
      z-index: $zindex-select-dropdown;
    }
  }

  // The selectpicker button
  .dropdown-toggle {
    margin: 0;
    padding: 0;
    font-size: 1rem;
    line-height: 1.8;

    &:active {
      background-color: transparent !important;
      color: $primary !important;
    }

    &:focus {
      outline: none !important;
    }

    .filter-option {
      position: static;
      top: 0;
      left: 0;
      float: left;
      height: 100%;
      width: 100%;
      text-align: left;
      overflow: hidden;
      flex: 0 1 auto; // for IE10

      @at-root .bs3#{&} {
        padding-right: inherit;
      }

      @at-root .input-group .bs3-has-addon#{&} {
        position: absolute;
        padding-top: inherit;
        padding-bottom: inherit;
        padding-left: inherit;
        float: none;

        .filter-option-inner {
          padding-right: inherit;
        }
      }
    }

    .filter-option-inner-inner {
      overflow: hidden;
    }

    // used to expand the height of the button when inside an input group
    .filter-expand {
      width: 0 !important;
      float: left;
      opacity: 0 !important;
      overflow: hidden;
    }

    .caret {
      position: absolute;
      top: 50%;
      right: 12px;
      margin-top: -2px;
      vertical-align: middle;
    }
  }

  .input-group &.form-control .dropdown-toggle {
    border-radius: inherit;
  }

  &[class*="col-"] .dropdown-toggle {
    width: 100%;
  }

  // The selectpicker dropdown
  .dropdown-menu {
    min-width: 100%;
    z-index: 9999;
    padding: 10px 20px;
    @include box-sizing(border-box);
    left: -72px !important;
    top: 0 !important;
    border-radius: 25px;
    min-width: 163px;
    border: 1px solid $gray-medium;
    width: 166px !important;
    font-size: 1rem;
    margin: 0;

    @media (min-width: 992px) {
      left: 0 !important;
    }

    @media (min-width: 1200px) {
      width: auto;
      padding: 15px 25px;
    }

    > .inner:focus {
      outline: none !important;
    }

    &.inner {
      position: static;
      float: none;
      border: 0;
      padding: 0;
      margin: 0;
      border-radius: 0;
      box-shadow: none;
    }

    .inner {
      width: 190px;
    }

    li {
      position: relative;

      &.active small {
        color: $input-alt-color-placeholder !important;
      }

      &.disabled a {
        @include cursor-disabled();
      }

      a {
        cursor: pointer;
        user-select: none;

        &.opt {
          position: relative;
          padding-left: 2.25em;
        }

        span.check-mark {
          display: none;
        }

        span.text {
          display: inline-block;
        }

        &.active {
          color: $primary;
          background-color: transparent;
        }
      }

      small {
        padding-left: 0.5em;
      }
    }

    .notify {
      position: absolute;
      bottom: 5px;
      width: 96%;
      margin: 0 2%;
      min-height: 26px;
      padding: 3px 5px;
      background: rgb(245, 245, 245);
      border: 1px solid rgb(227, 227, 227);
      @include box-shadow(inset 0 1px 1px fade(rgb(0, 0, 0), 5));
      pointer-events: none;
      opacity: 0.9;
      @include box-sizing(border-box);

      &.fadeOut {
        animation: 300ms linear 750ms forwards bs-notify-fadeOut;
      }
    }
  }

  .no-results {
    padding: 3px;
    background: #f5f5f5;
    margin: 0 5px;
    white-space: nowrap;
  }

  &.fit-width .dropdown-toggle {
    .filter-option {
      position: static;
      display: inline;
      padding: 0;
    }

    .filter-option-inner,
    .filter-option-inner-inner {
      display: inline;
      color: $primary;
    }

    .bs-caret:before {
      content: '\00a0';
    }

    .caret {
      position: static;
      top: auto;
      margin-top: -1px;
    }
  }

  &.show-tick .dropdown-menu {
    .selected span.check-mark {
      position: absolute;
      display: inline-block;
      right: 15px;
      top: 5px;
    }

    li a span.text {
      margin-right: 34px;
    }
  }

  // default check mark for use without an icon font
  .bs-ok-default:after {
    content: '';
    display: block;
    width: 0.5em;
    height: 1em;
    border-style: solid;
    border-width: 0 0.26em 0.26em 0;
    transform: rotate(45deg);
  }
}

.bootstrap-select.show-menu-arrow {
  &.open > .dropdown-toggle,
  &.show > .dropdown-toggle {
    z-index: ($zindex-select-dropdown + 1);
  }

  .dropdown-toggle .filter-option {
    &:before {
      content: '';
      border-left: 7px solid transparent;
      border-right: 7px solid transparent;
      border-bottom: 7px solid $color-grey-arrow;
      position: absolute;
      bottom: -4px;
      left: 9px;
      display: none;
    }

    &:after {
      content: '';
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;
      border-bottom: 6px solid white;
      position: absolute;
      bottom: -4px;
      left: 10px;
      display: none;
    }
  }

  &.dropup .dropdown-toggle .filter-option {
    &:before {
      bottom: auto;
      top: -4px;
      border-top: 7px solid $color-grey-arrow;
      border-bottom: 0;
    }

    &:after {
      bottom: auto;
      top: -4px;
      border-top: 6px solid white;
      border-bottom: 0;
    }
  }

  &.pull-right .dropdown-toggle .filter-option {
    &:before {
      right: 12px;
      left: auto;
    }

    &:after {
      right: 13px;
      left: auto;
    }
  }

  &.open > .dropdown-toggle .filter-option,
  &.show > .dropdown-toggle .filter-option {
    &:before,
    &:after {
      display: block;
    }
  }
}

.bs-searchbox,
.bs-actionsbox,
.bs-donebutton {
  padding: 4px 8px;
}

.bs-actionsbox {
  width: 100%;
  @include box-sizing(border-box);

  & .btn-group button {
    width: 50%;
  }
}

.bs-donebutton {
  float: left;
  width: 100%;
  @include box-sizing(border-box);

  & .btn-group button {
    width: 100%;
  }
}

.bs-searchbox {
  & + .bs-actionsbox {
    padding: 0 8px 4px;
  }

  & .form-control {
    margin-bottom: 0;
    width: 100%;
    float: none;
  }
}
