////// AnyDesk Variables After

//// Only define variables if the following 2 things are given:
//// 1. You want to create totally new variables WITH values which contain variables from "_variables.scss" or "_variables_custom_before.scss"
//// 2. You want to override variables from Bootstrap WITH values which contain variables from "_variables.scss" or "_variables_custom_after.scss"


//// Color system

$link-color-info:         theme-color("info") !default;
$link-hover-color-info:   darken($link-color-info, 15%) !default;


//// Buttons

$btn-font-weight:             $font-weight-bold !default;