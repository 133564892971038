////// AnyDesk Tables

//// Table Supercharged

.table-supercharged {
  font-size: 0.8rem;
  font-weight: 300;
  margin-bottom: 0;

  @media (min-width: 576px) {
    font-size: 1.2rem;
  }

  tbody tr:nth-of-type(odd) {
    background: rgb(255,255,255);
    background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 7%, rgba(255,255,255,1) 100%);
  }

  tr {
    th {
      border-top: none;
      border-bottom: 1px solid white;

      @media (max-width: 575px) {
        padding: .5rem;
      }
    }

    td {
      border-top: none;
      border-bottom: 1px solid white;

      @media (max-width: 575px) {
        padding: .5rem;
      }
    }
  }
}
